import React, { useState } from 'react'

import {
  makeStyles,
  Checkbox,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SettingsIcon from '@material-ui/icons/List'
import LockIcon from '@material-ui/icons/LockOpen'
import StatusIcon from '@material-ui/icons/PermIdentity'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import SearchIcon from '@material-ui/icons/Search'
import { format } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Column, RowInfo, SortingRule } from 'react-table'

import TablePanel from 'components/Blocks/Accordions/TablePanel'
import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import FilterButton from 'components/Blocks/Filters/FilterButton'
import FilterList from 'components/Blocks/Filters/FilterList'
import DebouncedTextInput from 'components/Blocks/FormHelpers/DebouncedTextInput'
import BulkSetStatusDialog from 'components/Settings/Team/Users/BulkSetStatusDialog'
import BulkUpdateUserPermissionsDialog from 'components/Settings/Team/Users/BulkUpdateUserPermissionsDialog'
import { UserDialogSteps } from 'components/Settings/Team/Users/UpdateUser'
import UpdateUserContainer from 'components/Settings/Team/Users/UpdateUserContainer'
import userData from 'components/Settings/Team/Users/UserData'
import {
  CurrentUserQuery,
  FilterTypePermissionInput,
  FilterTypeType,
  GroupNode,
  UserNode,
} from 'generated/graphql'
import { URLS } from 'utils/constants'
import { canAssignDataAccess } from 'utils/solution'
import { SettingsUser } from 'utils/types'

const useStyles = makeStyles(({ palette, spacing }) => ({
  addButton: {
    marginBottom: spacing(2),
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellText: {
    marginRight: spacing(1),
    width: '80%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  actionsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 10,
    marginLeft: 20,
    '& >div': {
      display: 'flex',
    },
  },
  searchIcon: {
    color: palette.common.navy65,
  },
  searchField: {
    marginLeft: 20,
    marginRight: 10,
  },
  tooltip: {
    '& ul': {
      listStyleType: 'none',
      padding: 0,
      paddingLeft: spacing(1),
    },
  },
  iconButton: {
    color: palette.common.navy65,
    '& >span>span': {
      paddingRight: spacing(1),
    },
  },
  filter: {
    '& >p': {
      marginLeft: spacing(2),
      marginBottom: 5,
    },
  },
  defaultCursor: {
    cursor: 'default',
  },
}))

export enum Status {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  INACTIVE = 'INACTIVE',
}
type HeaderName = 'NAME' | 'EMAIL' | 'GROUPS' | 'JOB TITLE' | 'DATA'

export type UserData = {
  id: UserNode['id']
  uIsAdmin: boolean
  uIsCurrentUser: boolean
  check: JSX.Element
  firstName: UserNode['firstName']
  lastName: UserNode['lastName']
  name: string
  email: UserNode['email']
  title: UserNode['title']
  groups: UserNode['groups']
  data: {
    userId: UserNode['id']
    uIsAdmin: boolean
    uIsCurrentUser: boolean
    step: number
    summary: JSX.Element
    tooltip: JSX.Element
  }
  lastActivity: UserNode['lastActivity']
  filterTypesMap: { [key: string]: FilterTypeType }
  status: string
  active: JSX.Element | null
}

type Props = {
  currentUser: CurrentUserQuery['currentUser']
  users: UserNode[]
  numTotalUsers: number
  selectedUsers: UserNode[]
  toggleSelectedUser(user: UserNode): void
  handleToggleIsActive: (user: UserNode) => void
  handleResetPassword: (userUuids: string[]) => void
  handleUpdateUser: (user: SettingsUser) => Promise<boolean>
  handleBulkUpdateUserPermissions: (arg: {
    uuidList: string[]
    filterTypes: FilterTypePermissionInput[]
  }) => Promise<boolean>
  handleBulkDeleteUsers: (userUuids: string[]) => void
  selectedFilters: { [filterTypeUuid: string]: string[] }
  updateSelectedFilters: (uuid: string, filterVals: string[]) => void
  searchQuery: string
  updateSearchQuery: (val: string) => void
  statuses: Status[]
  updateStatuses: (statuses: Status[]) => void
  pageSize: number
  loading: boolean
  page: number
  setPage: (newPage: number) => void
  onLoadPage: (newPage: number) => void
  hasNextPage: boolean
  updateSort: (sortList: SortingRule[]) => void
}

// This component always receives in the "users" variable just the slice of users required to display in the current page.
// The users for this page are fetched in the container using GraphQL params like offset and limit
const Users: React.FC<Props> = ({
  currentUser,
  users,
  numTotalUsers,
  handleToggleIsActive,
  handleResetPassword,
  handleUpdateUser,
  handleBulkUpdateUserPermissions,
  handleBulkDeleteUsers,
  selectedFilters,
  updateSelectedFilters,
  searchQuery,
  updateSearchQuery,
  statuses,
  updateStatuses,
  pageSize,
  loading,
  page,
  setPage,
  onLoadPage,
  updateSort,
  selectedUsers,
  toggleSelectedUser,
}) => {
  const classes = useStyles()
  const [showFilters, setShowFilters] = useState(false)
  const [openUpdateUserDialog, setOpenUpdateUserDialog] = useState(false)
  const [openBulkUserPermissionsDialog, setOpenBulkUserPermissionsDialog] = useState(false)
  const [openBulkSetStatus, setOpenBulkSetStatus] = useState(false)
  const [userIdToUpdate, setUserIdToUpdate] = useState<string | null>(null)
  const [step, setStep] = useState(UserDialogSteps.USER_INFO)

  const onPageChange = (newPage: number) => {
    onLoadPage(newPage)
    setPage(newPage)
  }

  const onSortedChange = (sorted: SortingRule[]) => {
    updateSort(sorted)
    setPage(0)
  }

  const EditableCell = ({ value }: { value: UserData['data'] }) => (
    <div className={classes.cell}>
      <Tooltip title={value.tooltip} classes={{ tooltip: classes.tooltip }}>
        <div className={classes.cellText}>{value.summary}</div>
      </Tooltip>
      {!value.uIsAdmin && !value.uIsCurrentUser && (
        <IconButton
          color="secondaryHover"
          onClick={() => {
            setOpenUpdateUserDialog(true)
            setUserIdToUpdate(value.userId)
            setStep(UserDialogSteps.DATA_ACCESS)
          }}
        >
          <EditIcon />
        </IconButton>
      )}
    </div>
  )

  const columns: Column[] = [
    {
      Header: null,
      accessor: 'check',
      width: 50,
      // Override the default first child props
      style: { paddingLeft: 5 },
    },
    {
      Header: 'NAME',
      accessor: 'name',
      width: 120,
      style: { textOverflow: 'ellipsis' },
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
      width: 150,
      style: { textOverflow: 'ellipsis' },
    },
    {
      Header: 'JOB TITLE',
      accessor: 'title',
      width: 100,
      style: { textOverflow: 'ellipsis' },
    },
    {
      Header: 'GROUPS',
      accessor: 'groups',
      Cell: row => row.value.map((g: UserData) => g.name).join(', '),
      width: 150,
      style: { textOverflow: 'ellipsis' },
    },
  ]
  if (canAssignDataAccess(currentUser.organization)) {
    columns.push({
      Header: 'DATA',
      accessor: 'data',
      Cell: EditableCell,
      sortable: false,
      headerClassName: classes.defaultCursor,
    })
  }
  columns.push({
    Header: 'LAST ACTIVE',
    accessor: 'lastActivity',
    Cell: row => {
      let text = 'No recent activity'
      if (row.value) {
        const date = new Date(row.value)
        const oneDay = 1000 * 60 * 60
        if (date.getTime() >= Date.now() - oneDay) {
          text = format(date, 'h:mm aa')
        } else {
          text = format(date, 'MM/dd/yyyy')
        }
      }
      // In case text is cuttoff, allow hover with full text
      return (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      )
    },
    width: 100,
  })
  columns.push({
    Header: 'STATUS',
    accessor: 'status',
    width: 80,
    style: { textOverflow: 'clip' },
    sortable: false,
    headerClassName: classes.defaultCursor,
  })
  columns.push({
    Header: 'SET ACTIVE',
    accessor: 'active',
    width: 110,
    sortable: false,
    headerClassName: classes.defaultCursor,
  })
  const usersData: UserData[] = users.map(u => {
    const userId = u.id
    const uIsAdmin = Boolean(u.isAdmin)
    const uIsCurrentUser = u.id === currentUser.id
    const dataColumn = () => userData(uIsAdmin, u.filterPermissions)
    return {
      id: u.id,
      uIsAdmin,
      uIsCurrentUser,
      check: (
        <Checkbox
          value={u.id}
          checked={selectedUsers.some(selectedUser => selectedUser.id === u.id)}
          onChange={() => toggleSelectedUser(u)}
        />
      ),
      firstName: u.firstName,
      lastName: u.lastName,
      name: `${u.firstName} ${u.lastName}`,
      email: u.email,
      title: u.title,
      groups: u.groups,
      data: { userId, uIsAdmin, uIsCurrentUser, step: 1, ...dataColumn() },
      lastActivity: u.lastActivity,
      filterTypesMap: (u.filterPermissions as FilterTypeType[]).reduce(
        (acc: { [key: string]: FilterTypeType }, filter: FilterTypeType) => {
          return { ...acc, [filter.filterTypeUuid]: filter }
        },
        {},
      ) as { [key: string]: FilterTypeType },
      status: upperFirst(u.status?.toLowerCase()),
      active:
        u.status !== Status.INVITED ? (
          <>
            {u.status === Status.INACTIVE && 'off'}
            <Switch checked={u.status === Status.ACTIVE} onChange={() => handleToggleIsActive(u)} />
            {u.status === Status.ACTIVE && 'on'}
          </>
        ) : null,
    }
  })
  const { isAdmin } = currentUser

  return (
    <>
      <BreadcrumbsItem to={URLS.ORG_SETTINGS.USERS}>Team</BreadcrumbsItem>
      {openUpdateUserDialog && (
        <UpdateUserContainer
          key={userIdToUpdate || 'new'}
          step={step}
          loading={loading}
          onUpdateUser={handleUpdateUser}
          onClose={() => {
            setOpenUpdateUserDialog(false)
            setUserIdToUpdate(null)
          }}
          userToUpdate={usersData.find(u => u.id === userIdToUpdate)}
        />
      )}
      <BulkUpdateUserPermissionsDialog
        open={openBulkUserPermissionsDialog}
        onClose={() => setOpenBulkUserPermissionsDialog(false)}
        userIds={selectedUsers.map(u => u.id)}
        loading={loading}
        onSubmit={handleBulkUpdateUserPermissions}
      />
      <BulkSetStatusDialog
        open={openBulkSetStatus}
        userIds={selectedUsers.map(u => u.id)}
        onClose={() => setOpenBulkSetStatus(false)}
      />
      <TablePanel
        interactive={false}
        clickable
        reactTableProps={{
          columns,
          data: usersData,
          onSortedChange,
          resizable: false,
          page,
          pageSize,
          pages: Math.ceil(numTotalUsers / pageSize),
          loading,
          onPageChange,
          // Use manual: true to disable the default sorting / pagination behavior.
          // There is buggy behavior with the other manual options (like manualSortBy)
          // The best option moving forward would be to build a simple UI table
          manual: true,
          getTdProps: (
            state: string,
            rowInfo: RowInfo | undefined,
            column: Column | undefined,
          ) => ({
            onClick: (evt: React.MouseEvent<HTMLElement>) => {
              const { original } = rowInfo as RowInfo
              const { id, groups } = original
              const header = (column as Column).Header as HeaderName
              const uIsAdmin = groups.some((g: GroupNode) => g.isAdmin)
              const HEADER_STEP_MAPPING = {
                NAME: UserDialogSteps.USER_INFO,
                EMAIL: UserDialogSteps.USER_INFO,
                GROUPS: UserDialogSteps.USER_INFO,
                'JOB TITLE': UserDialogSteps.USER_INFO,
                DATA: UserDialogSteps.DATA_ACCESS,
              }
              if (
                !HEADER_STEP_MAPPING[header] ||
                // Don't allow editing admin data settings
                (uIsAdmin && ['DATA'].includes(header))
              ) {
                return
              }
              const target: HTMLElement = evt.target as HTMLElement
              if (target.tagName === 'DIV') {
                setOpenUpdateUserDialog(true)
                setUserIdToUpdate(id)
                setStep(HEADER_STEP_MAPPING[header])
              }
            },
          }),
        }}
        actions={
          <div className={classes.actionsRow}>
            <div>
              <Typography variant="h6">Team</Typography>
              <DebouncedTextInput
                name="team"
                InputComponent={TextField}
                delayTime={500}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
                className={classes.searchField}
                value={searchQuery}
                onUpdate={val => {
                  updateSearchQuery(val)
                  // This should happen in a useEffect when we convert this page to hooks
                  setPage(0)
                }}
              />
            </div>
            <div>
              {isAdmin && (
                <Tooltip
                  title={
                    selectedUsers.some(user => user.isAdmin || user.status === Status.INVITED)
                      ? 'Data Access — limited to non-admin users'
                      : 'Data Access'
                  }
                >
                  <div>
                    <Button
                      disabled={
                        selectedUsers.length === 0 || selectedUsers.some(user => user.isAdmin)
                      }
                      color="secondaryNoBackground"
                      className={classes.iconButton}
                      onClick={() => setOpenBulkUserPermissionsDialog(true)}
                    >
                      <SettingsIcon />
                    </Button>
                  </div>
                </Tooltip>
              )}
              {isAdmin && (
                <Tooltip
                  title={
                    selectedUsers.some(user => user.status === Status.INVITED)
                      ? 'Set Status — limited to active/inactive users'
                      : 'Set Status'
                  }
                >
                  <div>
                    <Button
                      disabled={
                        selectedUsers.length === 0 ||
                        selectedUsers.some(user => user.status === Status.INVITED)
                      }
                      color="secondaryNoBackground"
                      className={classes.iconButton}
                      onClick={() => setOpenBulkSetStatus(true)}
                    >
                      <StatusIcon />
                    </Button>
                  </div>
                </Tooltip>
              )}
              {currentUser.canResetUsersPasswords && (
                <Tooltip
                  title={
                    selectedUsers.every(u => u.isActive)
                      ? 'Reset Password'
                      : 'Please unselect inactive users before resetting passwords'
                  }
                >
                  <div>
                    <Button
                      disabled={!selectedUsers.every(u => u.isActive) || selectedUsers.length === 0}
                      color="secondaryNoBackground"
                      className={classes.iconButton}
                      onClick={() => handleResetPassword(selectedUsers.map(user => user.id))}
                    >
                      <LockIcon />
                    </Button>
                  </div>
                </Tooltip>
              )}
              {isAdmin && (
                <Tooltip title="Delete User">
                  <div>
                    <Button
                      disabled={selectedUsers.length === 0}
                      color="secondaryNoBackground"
                      className={classes.iconButton}
                      onClick={() => handleBulkDeleteUsers(selectedUsers.map(user => user.id))}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
            <div>
              <FilterButton
                filters={selectedFilters}
                toggleFilters={() => setShowFilters(!showFilters)}
              />
              {currentUser.canInviteUsers && (
                <Button
                  id="addNewTeamMember"
                  color="primary"
                  onClick={() => setOpenUpdateUserDialog(true)}
                >
                  <PersonAddIcon />
                </Button>
              )}
            </div>
          </div>
        }
      >
        {showFilters && (
          <FilterList
            filters={currentUser.filters}
            selectedFilters={selectedFilters}
            updateSelectedFilters={(uuid, filterVals) => {
              updateSelectedFilters(uuid, filterVals)
              // This should happen in a useEffect when we convert this page to hooks
              setPage(0)
            }}
          >
            <div id="activeStatusFilter" className={classes.filter}>
              <Typography color="textSecondary" variant="body2">
                Status
              </Typography>
              <CheckboxDropdown
                withLeftMargin
                id="activeStatus"
                menuItems={[
                  { value: Status.ACTIVE, text: 'Active' },
                  { value: Status.INVITED, text: 'Invited' },
                  { value: Status.INACTIVE, text: 'Inactive' },
                ]}
                selectedItems={statuses}
                onChange={(newStatuses: Status[]) => {
                  updateStatuses(newStatuses)
                  // This should happen in a useEffect when we convert this page to hooks
                  setPage(0)
                }}
                width={120}
              />
            </div>
          </FilterList>
        )}
      </TablePanel>
    </>
  )
}

export default Users

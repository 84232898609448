import React from 'react'

import { GlobalNav, GlobalNavVariation } from '@activated-insights/global-nav'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  globalNav: {
    zIndex: 1033,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  appBarContainer: {
    minHeight: 66, // appBar height + padding
  },
})

const AnonymousHeader = ({ classes }) => (
  <div className={classes.appBarContainer}>
    <div className={classes.globalNav}>
      <GlobalNav
        canAccessHcpExperience={false}
        canAccessHcpReputation={false}
        canAccessHcpTraining={false}
        hideProfileDropdown
        variation={GlobalNavVariation.ACTIVATED_INSIGHTS}
        companyName=""
        userName=""
        className={classes.globalNav}
      />
    </div>
  </div>
)

export default withStyles(styles)(AnonymousHeader)

import React, { ReactElement } from 'react'

import { Typography } from '@material-ui/core'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import StatementLabel from 'components/Insights/Blocks/StatementLabel'
import { InsightsBenchmark } from 'components/Insights/InsightsTypes'
import PrintableRechart from 'components/Insights/Printable/PrintableRechart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  OrganizationSolutionFragment,
  OrganizationResidentSolutionFragment,
  InsightsSurveyQuery,
  InsightsStatementsQuery,
  InsightsStatementScoreFragment,
  InsightsModulesEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatScore } from 'utils'
import { RESIDENT_QUESTION_FOCUSES } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

/**
 * Component for rendering snapshot charts for ranked statements
 */
type SProps = {
  payload: Array<{
    payload: {
      label: string
      benchmarkName: string
      benchmark: number | null | undefined
      companyScore: number
    }
  }>
  label: string
}
const StatementTooltip: React.FC<SProps> = ({ payload, label }) => {
  if (!payload || payload.length < 1) {
    return <div />
  }
  const company = payload[0]
  let benchmark
  if (payload.length > 1) {
    benchmark = payload[1]
  }
  const [, statement, , categoryName] = label.split('::')
  return (
    <div style={{ backgroundColor: '#FFF', maxWidth: 300, padding: 8 }}>
      <Typography variant="body2">{statement}</Typography>
      <Typography variant="body2">
        <span style={{ color: colors.navy65 }}>{categoryName}:</span> {company.payload.companyScore}
        %
      </Typography>
      {benchmark && (
        <Typography variant="body2">
          <span style={{ color: colors.navy65 }}>{benchmark.payload.benchmarkName}:</span>{' '}
          {benchmark.payload.benchmark}%
        </Typography>
      )}
    </div>
  )
}

type Props = {
  survey: InsightsSurveyQuery['survey']
  statements: (InsightsStatementsQuery['statements'][0] | InsightsStatementScoreFragment)[]
  filters: string[]
  benchmark: InsightsBenchmark
  solution: OrganizationSolutionFragment | OrganizationResidentSolutionFragment
  scoreKey: 'positive' | 'negative'
  benchmarkScoreKey: 'benchmarkPositive' | 'benchmarkNegative'
}
const RechartsStatements: React.FC<Props> = ({
  survey,
  statements,
  filters,
  benchmark,
  solution,
  scoreKey,
  benchmarkScoreKey,
}) => {
  let categoryName = 'Company Overall'
  if (filters.length) {
    categoryName = 'Selected Filters'
  }
  const massagedData = statements.map(d => {
    const focus = d.focus || (d.residentFocus && RESIDENT_QUESTION_FOCUSES[d.residentFocus])
    return {
      label: `${getInsightsPage(
        survey.uuid,
        InsightsModulesEnum.COMPARISONS,
        survey.productType,
      )}?statement=${d.code}::${d.label}::${focus}::${categoryName}`,
      companyScore: formatScore(Number(d[scoreKey])),
      benchmark: formatScore(Number(d[benchmarkScoreKey])),
      benchmarkName: benchmark.name,
    }
  })
  const scoreColor = {
    positive: colors.success,
    negative: colors.danger,
  }[scoreKey]

  return (
    <ResponsiveContainer height={450}>
      <BarChart
        data={massagedData}
        layout="vertical"
        margin={{ top: 0, right: 48, left: 24, bottom: 16 }}
      >
        <CartesianGrid
          horizontal={false}
          stroke={colors.navy25}
          strokeWidth="0.5"
          shapeRendering="crispEdges"
        />
        <XAxis
          orientation="top"
          type="number"
          domain={[0, 100]}
          tickLine={false}
          ticks={[0, 20, 40, 60, 80, 100]}
          axisLine={false}
        />
        <YAxis
          width={300}
          type="category"
          dataKey="label"
          tick={<StatementLabel linkToComparisons={solution.insightsComparisons} />}
          tickLine={false}
          axisLine={false}
        />
        <Tooltip cursor={{ fill: colors.navy11 }} content={StatementTooltip} />
        <Legend align="left" wrapperStyle={{ width: 440, bottom: 0, right: 0 }} />
        <Bar
          barSize={18}
          dataKey="companyScore"
          name={categoryName}
          fill={scoreColor}
          // Necessary to render the formatter
          // per https://stackoverflow.com/questions/55722306/label-list-is-not-showing-in-recharts
          isAnimationActive={false}
        >
          <LabelList dataKey="companyScore" position="insideRight" formatter={val => `${val}%`} />
        </Bar>
        <Bar
          barSize={18}
          dataKey="benchmark"
          name={benchmark.name}
          fill={colors.purple}
          isAnimationActive={false}
        >
          <LabelList dataKey="benchmark" position="insideRight" formatter={val => `${val}%`} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

type TitleProps = {
  title: string
  tooltipText?: string | ReactElement
  description?: string
  snapId?: string
  hasTimeTrending?: boolean
  timeTrendingChartKey?: TimeTrendingChartKey
}
const RankedStatementsCard: React.FC<Props & TitleProps> = ({
  title,
  description,
  tooltipText,
  snapId,
  hasTimeTrending,
  timeTrendingChartKey,
  ...restProps
}) => {
  return (
    <>
      <SnapshotChartHeader
        title={title}
        description={description}
        tooltip={tooltipText}
        snapId={snapId}
        hasTimeTrending={hasTimeTrending}
        timeTrendingChartKey={timeTrendingChartKey}
      />
      <PrintableRechart printWidth={650}>
        <RechartsStatements {...restProps} />
      </PrintableRechart>
    </>
  )
}

export default RankedStatementsCard

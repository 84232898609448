import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, Checkbox, DialogContent, Typography } from '@material-ui/core'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'

import Panel from 'components/Blocks/Accordions/Panel'
import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import { FilterProductTypePermissionEnum, CurrentUserDocument } from 'generated/graphql'
import { CONTACT_EMAIL } from 'utils/constants'
import { organizationHasChooseOneDataAccess } from 'utils/solution'

const useStyles = makeStyles(({ spacing }) => ({
  rootStep: {
    marginLeft: -spacing(3),
    marginRight: -spacing(3),
    display: 'block',
  },
  columns: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxHeight: 350,
    overflow: 'auto',
  },
  choice3: {
    marginBottom: 8,
    width: 173,
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkboxRoot: {
    padding: 0,
  },
  checkboxLabel: {
    fontSize: '1.6rem',
    lineHeight: '21px',
    display: 'inline-block',
    marginLeft: spacing(1),
    marginRight: spacing(1),
    paddingTop: 1,
    cursor: 'pointer',
    wordBreak: 'break-word',
  },
  panelRoot: {
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 1px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
    '&:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  helperMessage: {
    marginLeft: spacing(2),
    marginTop: spacing(2),
  },
}))

const FilterAccessSettings = ({
  selectedFilters,
  updateFilters,
  submitLabel,
  onClose,
  onSubmit,
  loading,
}) => {
  const classes = useStyles()
  const client = useApolloClient()

  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser

  let initialExpanded
  const first = Object.keys(selectedFilters)[0]
  if (first) {
    initialExpanded = first
  } else {
    const accessControlFilterTypes = currentUser.filters.filter(
      ft => ft.accessControlProductType !== FilterProductTypePermissionEnum.NONE,
    )
    if (accessControlFilterTypes.length) {
      initialExpanded = accessControlFilterTypes[0].filterTypeUuid
    }
  }

  const [expanded, setExpanded] = useState(initialExpanded)
  const chooseOne = organizationHasChooseOneDataAccess(currentUser.organization)

  const handleCheckAccessAll = filterType => {
    const newFilters = cloneDeep(selectedFilters)

    let existingFT = newFilters[filterType.filterTypeUuid]
    if (existingFT) {
      existingFT.accessToAll = !existingFT.accessToAll
    } else {
      existingFT = cloneDeep(filterType)
      existingFT.accessToAll = true
      newFilters[filterType.filterTypeUuid] = existingFT
    }
    existingFT.filterValues = []
    if (!existingFT.accessToAll) {
      // User unchecked select all, remove it from selected filters
      delete newFilters[filterType.filterTypeUuid]
    }
    updateFilters(newFilters)
  }

  const handleCheck = (filterType, filterValue) => {
    const newFilters = cloneDeep(selectedFilters)

    let existingFT = newFilters[filterType.filterTypeUuid]
    if (!existingFT) {
      existingFT = cloneDeep(filterType)
      existingFT.accessToAll = false
      existingFT.filterValues = [cloneDeep(filterValue)]
      newFilters[existingFT.filterTypeUuid] = existingFT
    } else {
      const newFVs = existingFT.filterValues.slice()
      const fvIndex = findIndex(newFVs, newFV => newFV.uuid === filterValue.uuid)
      if (fvIndex > -1) {
        // Removing filter value
        newFVs.splice(fvIndex, 1)
      } else {
        newFVs.push(cloneDeep(filterValue))
      }
      existingFT.filterValues = newFVs
    }
    if (!existingFT.filterValues.length > 0) {
      delete newFilters[filterType.filterTypeUuid]
    } else {
      newFilters[filterType.filterTypeUuid] = existingFT
    }
    updateFilters(newFilters)
  }

  const handleExpand = uuid => {
    if (uuid === expanded) {
      setExpanded(null)
    } else {
      setExpanded(uuid)
    }
  }

  const selectedFTs = Object.keys(selectedFilters)
  const selectedFVs = []
  Object.values(selectedFilters).forEach(ft => {
    ft.filterValues.forEach(fv => selectedFVs.push(fv.uuid))
  })
  const hasAccessToAll = ft => {
    let all = false
    if (selectedFTs.includes(ft.filterTypeUuid)) {
      all = selectedFilters[ft.filterTypeUuid].accessToAll
    }
    return all
  }
  const isDisabled = ft => {
    // these need to be set correctly in DB based on solution
    if (ft.accessControlProductType === FilterProductTypePermissionEnum.NONE) return true
    if (chooseOne) {
      return selectedFTs.length > 0 && ft.filterTypeUuid !== selectedFTs[0]
    }
    return false
  }

  const submitDisabled =
    selectedFVs.length === 0 && currentUser.filters.filter(ft => hasAccessToAll(ft)).length === 0

  return (
    <>
      <DialogContent dividers className={classes.rootStep}>
        {currentUser.filters.map(filterType => (
          <Panel
            classes={{ panelRoot: classes.panelRoot }}
            key={filterType.filterTypeUuid}
            tooltip={
              !isDisabled(filterType)
                ? ''
                : `To learn more about how to grant access by ${filterType.name}, email ${CONTACT_EMAIL}.`
            }
            expanded={expanded === filterType.filterTypeUuid}
            disabled={isDisabled(filterType)}
            onExpandChange={() => handleExpand(filterType.filterTypeUuid)}
            title={filterType.name}
          >
            <div
              className={classes.columns}
              // style={{ maxHeight: Math.min((filterType.filterValues.length / 3) * 50, 400) }}
            >
              {currentUser.isAdmin && (
                <div className={classes.choice3}>
                  <Checkbox
                    id={`all-${filterType.filterTypeUuid}`}
                    classes={{ root: classes.checkboxRoot }}
                    checked={hasAccessToAll(filterType)}
                    onChange={() => handleCheckAccessAll(filterType)}
                  />
                  <label
                    htmlFor={`all-${filterType.filterTypeUuid}`}
                    className={classes.checkboxLabel}
                  >
                    All {filterType.namePlural}
                  </label>
                </div>
              )}
              {filterType.filterValues.map(fv => (
                <div key={fv.uuid} className={classes.choice3}>
                  <Checkbox
                    id={fv.uuid}
                    classes={{ root: classes.checkboxRoot }}
                    disabled={hasAccessToAll(filterType)}
                    checked={hasAccessToAll(filterType) || selectedFVs.includes(fv.uuid)}
                    onChange={() => handleCheck(filterType, fv)}
                  />
                  <label htmlFor={fv.uuid} className={classes.checkboxLabel}>
                    {fv.name}
                  </label>
                </div>
              ))}
            </div>
          </Panel>
        ))}
        <Typography color="textSecondary" variant="body2" className={classes.helperMessage}>
          Select at least one checkbox. Otherwise this team member won't be able to see any results.
        </Typography>
      </DialogContent>
      <ActionDialogButtons
        isSubmitting={loading}
        onClose={onClose}
        submitButtonText={submitLabel}
        onSubmit={onSubmit}
        submitDisabled={submitDisabled}
      />
    </>
  )
}

export default FilterAccessSettings

import React, { useContext } from 'react'

import { Typography } from '@material-ui/core'
import { addMonths, subMonths, addDays } from 'date-fns'
import range from 'lodash/range'

import { InsightsSurvey, InsightsTabProps } from 'components/Insights/InsightsTypes'
import { EchartsScore, getValidTimeTrendingSurveys } from 'components/Insights/TimeTrending/utils'
import { StoreContext } from 'config/LocalStorage'
import { InsightsModulesEnum, SurveyTypeEnum } from 'generated/graphql'
import { colors } from 'shared/theme'
import { TimeframeDropDownLocation, TimeTrendingType, CUSTOM_SURVEY_TYPES } from 'utils/constants'
import { getFormattedSurveyDate } from 'utils/dateUtils'
import { Solution, SurveyNode } from 'utils/types'

// Timeframe and Time-Trending settings. Setting defaults for the tabs that use the feature.
// Tabs without the feature will omit the properties.
export const getTimeframeAndTimeTrendingTabSettings = (
  insightsTab: InsightsModulesEnum,
  survey: InsightsSurvey,
  solution: Solution,
  availableSurveys: SurveyNode[],
) => {
  let timeTrendingType: InsightsTabProps['timeTrendingType'] = null
  let timeTrendingIconLocation: InsightsTabProps['timeTrendingIconLocation'] = null
  // Tab can have time-trending.
  if (
    [InsightsModulesEnum.SNAPSHOTS, InsightsModulesEnum.STATEMENTS].includes(insightsTab) &&
    solution.insightsTimeTrending
  ) {
    // Conditions to show "surveys" time-trending.
    if (
      [
        SurveyTypeEnum.RESIDENT_ENGAGEMENT,
        SurveyTypeEnum.RESIDENT_PULSE,
        SurveyTypeEnum.TI,
        SurveyTypeEnum.PULSE,
      ].includes(survey.type) &&
      getValidTimeTrendingSurveys(availableSurveys).length > 1
    ) {
      timeTrendingType = TimeTrendingType.SURVEYS
    }
    // Conditions to show "timeframe" time-trending:
    // Survey is open-ended and started at least 3 months ago.
    else if (
      [SurveyTypeEnum.RESIDENT_DISCHARGE, SurveyTypeEnum.RESIDENT_END_OF_SERVICE].includes(
        survey.type,
      ) &&
      !survey.endDate &&
      Date.now() > addMonths(new Date(survey.startDate), 3).getTime()
    ) {
      timeTrendingType = TimeTrendingType.TIMEFRAME
    }
    if (
      timeTrendingType === TimeTrendingType.TIMEFRAME &&
      insightsTab === InsightsModulesEnum.SNAPSHOTS
    ) {
      timeTrendingIconLocation = TimeframeDropDownLocation.CONTROLS
    } else if (timeTrendingType) {
      timeTrendingIconLocation = TimeframeDropDownLocation.COMPONENT
    }
  }
  let timeframeDropdownLocation: InsightsTabProps['timeframeDropdownLocation'] = null
  // Conditions to show "timeframe" dropdown
  if (survey.isRecurring || survey.type === SurveyTypeEnum.END_OF_EMPLOYMENT) {
    timeframeDropdownLocation = TimeframeDropDownLocation.CONTROLS
  } else if (
    [SurveyTypeEnum.RESIDENT_DISCHARGE, SurveyTypeEnum.RESIDENT_END_OF_SERVICE].includes(
      survey.type,
    )
  ) {
    if (insightsTab === InsightsModulesEnum.COMPARISONS) {
      timeframeDropdownLocation = TimeframeDropDownLocation.COMPONENT
    } else {
      timeframeDropdownLocation = TimeframeDropDownLocation.CONTROLS
    }
  } else if (CUSTOM_SURVEY_TYPES.includes(survey.type)) {
    timeframeDropdownLocation = TimeframeDropDownLocation.COMPONENT
  }

  return {
    timeTrendingType,
    timeTrendingIconLocation,
    timeframeDropdownLocation,
  }
}

export const timeRangeDescription = (timeRanges: ChartTimeRange[]) => {
  return (
    <Typography>
      Time frame:&nbsp;
      <span style={{ color: colors.navy }}>
        {getFormattedSurveyDate({
          endDate: timeRanges[0].startDate,
          includeDay: true,
          excludeComma: false,
        })}
      </span>
      &nbsp;&nbsp;to&nbsp;&nbsp;
      <span style={{ color: colors.navy }}>
        {getFormattedSurveyDate({
          endDate: timeRanges[timeRanges.length - 1].endDate,
          includeDay: true,
          excludeComma: false,
        })}
      </span>
    </Typography>
  )
}

export const getTimeTrendingTimeframes = (now = new Date()) => {
  // Use end of the day to make comparisons static.
  now?.setHours(23, 0, 0)

  const getTimeRanges = (monthsPerRange: number, numRanges: number) => {
    // Include a range for the given number of months within the overall timeframe.
    return range(0, numRanges)
      .map(idx => {
        const startDate = addDays(subMonths(now, (idx + 1) * monthsPerRange), 1)
        // Beginning of day.
        startDate.setHours(0, 0, 0)
        startDate.setMilliseconds(0)
        const endDate = subMonths(now, idx * monthsPerRange)
        endDate.setMilliseconds(0)
        return {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          // uuid has to be a string. When the uuids are used as keys to create objects later in the logic, JS will automatically convert the uuid to a number which will order the object by number (as if it's an array), instead of the insertion order we need to preserve here.
          uuid: `uuid${idx + 1}`,
        }
      })
      .reverse()
  }
  return [
    {
      label: 'Last 3 months',
      ranges: getTimeRanges(1, 3),
    },
    {
      label: 'Last 6 months',
      ranges: getTimeRanges(2, 3),
    },
    {
      label: 'Last 9 months',
      ranges: getTimeRanges(3, 3),
    },
    {
      label: 'Last year',
      ranges: getTimeRanges(3, 4),
    },
    {
      label: 'Last 2 years',
      ranges: getTimeRanges(6, 4),
    },
  ]
}

export const getDeltaAndText = (scores: EchartsScore[]) => {
  let deltaText = 'the same as '
  const scoreDelta = scores[scores.length - 1][1]! - scores[0][1]!
  if (scoreDelta > 0) {
    deltaText = `${scoreDelta}% higher than`
  } else if (scoreDelta < 0) {
    deltaText = `${Math.abs(scoreDelta)}% lower than`
  }
  return { scoreDelta, deltaText }
}

export type ChartTimeRange = {
  uuid: string
  startDate: string
  endDate: string
  minShowableResults: number
}

export const useTimeTrendingTimeframeSettings = (survey: InsightsSurvey) => {
  const {
    store: { timeTrendingSettingsBySurvey },
  } = useContext(StoreContext)
  const { enabled, timeframe } = timeTrendingSettingsBySurvey[survey.uuid] || {
    enabled: false,
    timeframe: null,
  }
  const timeRanges: ChartTimeRange[] = getTimeTrendingTimeframes()
    .find(tf => tf.label === timeframe)
    // Classic time-trending needs to prepare for surveys with specific properties (name, minShowable, etc.).
    // In order to re-use utils with classic time-trending,
    // assign the relevant survey value to each time range.
    ?.ranges.map(timeRange => ({
      ...timeRange,
      minShowableResults: survey.minShowableResults,
      name: survey.name,
      // Properties for formatting tooltip titles
      isTimeRange: true,
      excludeComma: false,
    }))!
  return { timeTrendingEnabled: enabled, timeRanges }
}

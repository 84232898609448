import React from 'react'

import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import StatementLabel from 'components/Insights/Blocks/StatementLabel'
import PrintableRechart from 'components/Insights/Printable/PrintableRechart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { InsightsModulesEnum } from 'generated/graphql'
import { colors } from 'shared/theme'
import { getInsightsPage } from 'utils/insightsUtils'

const StatementTooltip = props => {
  if (!props.payload.length) {
    return <div />
  }
  const [positive, , benchmark] = props.payload
  const [, statement, ,] = props.label.split('::')
  return (
    <div style={{ backgroundColor: '#FFF', maxWidth: 300, padding: 8 }}>
      <Typography variant="body2">{statement}</Typography>
      <Typography variant="body2">
        <span style={{ color: colors.navy65 }}>Positive Responses:</span>{' '}
        {Math.round(positive.payload.positive)}%
      </Typography>
      <Typography variant="body2">
        <span style={{ color: colors.navy65 }}>Inconsistent Responses:</span>{' '}
        {Math.round(positive.payload.inconsistent)}%
      </Typography>
      {benchmark.payload.benchmark && (
        <Typography variant="body2">
          <span style={{ color: colors.navy65 }}>{benchmark.payload.benchmarkName}:</span>{' '}
          {Math.round(benchmark.payload.benchmark)}%
        </Typography>
      )}
    </div>
  )
}

const RechartsStatements = ({ survey, data, benchmark, solution }) => {
  const massagedData = data.map(d => ({
    label: `${getInsightsPage(
      survey.uuid,
      InsightsModulesEnum.COMPARISONS,
      survey.productType,
    )}?statement=${d.code}::${d.label}::${d.focus}`,
    positive: d.positive,
    inconsistent: d.inconsistent,
    benchmark: d.benchmarkPositive,
    benchmarkName: benchmark.name,
  }))
  massagedData.sort((a, b) => b.positive + b.inconsistent - (a.positive, b.positive))
  const barLabelFormatter = val => (val >= survey.minShowableResults && `${Math.round(val)}%`) || ''
  return (
    <ResponsiveContainer height={450}>
      <BarChart
        data={massagedData}
        layout="vertical"
        margin={{ top: 0, right: 48, left: 24, bottom: 16 }}
      >
        <CartesianGrid
          horizontal={false}
          stroke={colors.navy25}
          strokeWidth="0.5"
          shapeRendering="crispEdges"
        />
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          orientation="top"
          type="number"
          domain={[0, 100]}
          tickLine={false}
          ticks={[0, 20, 40, 60, 80, 100]}
          axisLine={false}
        />
        <YAxis
          width={300}
          type="category"
          dataKey="label"
          tick={<StatementLabel linkToComparisons={solution.insightsComparisons} />}
          tickLine={false}
          axisLine={false}
        />
        <Tooltip cursor={{ fill: colors.navy11 }} content={StatementTooltip} />
        <Legend align="left" wrapperStyle={{ width: 440, bottom: 0, right: 0 }} />
        <Bar
          barSize={30}
          stackId="a"
          dataKey="positive"
          name="Positive Responses"
          fill={colors.success}
          isAnimationActive={false}
        >
          <LabelList dataKey="positive" position="insideRight" formatter={barLabelFormatter} />
        </Bar>
        <Bar
          barSize={30}
          stackId="a"
          dataKey="inconsistent"
          name="Inconsistent Responses"
          fill={colors.warning}
        >
          <LabelList dataKey="inconsistent" position="insideRight" formatter={barLabelFormatter} />
        </Bar>
        <Bar
          barSize={3}
          dataKey="benchmark"
          name={benchmark.name}
          fill={colors.purple}
          isAnimationActive={false}
        >
          <LabelList dataKey="benchmark" position="right" formatter={barLabelFormatter} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

const MoveableMiddleCard = ({ survey, statements, benchmark, solution }) => {
  return (
    <>
      <SnapshotChartHeader
        title="Recommended Focus Areas"
        description="To improve your overall employee experience, pick 1-2 statements from your moveable middle to focus on."
        tooltip="These statements had the highest percentage of employees that answered 'Sometimes true/Sometimes untrue'. They represent the best opportunities for improvement - the gray vertical bars show what your scores would be if all employees who answered 'sometimes true/sometimes untrue' changed their answers to 'often true' or 'almost always true'."
        snapId="moveable-snap"
      />
      <PrintableRechart printWidth={650}>
        <RechartsStatements
          survey={survey}
          data={statements}
          benchmark={benchmark}
          solution={solution}
        />
      </PrintableRechart>
    </>
  )
}

MoveableMiddleCard.propTypes = {
  statements: PropTypes.array.isRequired,
  benchmark: PropTypes.object.isRequired,
}

export default MoveableMiddleCard

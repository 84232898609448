import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import InsightsOverviewRightDetail from 'components/Insights/Blocks/InsightsOverviewRightDetail'
import { ResponseRateTitleAndPie } from 'components/Insights/Blocks/ResponseRateTitleAndPie'
import TimeTrendingIcon from 'components/Insights/Blocks/TimeTrendingIcon'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import { ResponseRateTypeEnum } from 'generated/graphql'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ spacing }) => ({
  timeTrending: {
    position: 'absolute',
    right: 12,
    top: spacing(),
  },
}))

type Props = {
  finished: number
  total: number
  hasTimeTrending: boolean
  surveyUuid: string
  showReportBy: ResponseRateTypeEnum
  minShowableResults: number
  showTitle?: boolean
}
// Just the card component when the data has been prefetched.
export const ResponseRateCard: React.FC<Props> = ({
  finished,
  total,
  hasTimeTrending,
  surveyUuid,
  showReportBy,
  minShowableResults,
  showTitle = true,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  let color = colors.success

  const rate = Math.round((finished / total) * 100)

  let title = ''
  if (showTitle) {
    title = 'Opportunity to Improve'
    if (rate >= 90) {
      title = 'Incredible!'
    } else if (rate >= 80) {
      title = 'Very Good'
    } else if (rate >= 60) {
      title = 'Good'
    } else if (rate >= 20) {
      title = 'Okay'
    } else {
      color = colors.warning
    }
  }

  return (
    <div id="response-rate-snap" className={classes.halfRoot}>
      <ResponseRateTitleAndPie
        surveyUuid={surveyUuid}
        minShowableResults={minShowableResults}
        finished={finished}
        total={total}
        showReportBy={showReportBy}
      />
      <InsightsOverviewRightDetail
        title={title}
        titleColor={color}
        score={`${finished}/${total}`}
        extraDescription={
          <Typography color="textSecondary">
            {Math.round(rate)}% of participants responded to the survey.
          </Typography>
        }
      />
      {hasTimeTrending && (
        <TimeTrendingIcon
          chartKey={TimeTrendingChartKey.RESIDENT_INDEX_SCORE}
          className={classes.timeTrending}
        />
      )}
    </div>
  )
}

export default ResponseRateCard

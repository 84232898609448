import React from 'react'

import isNil from 'lodash/isNil'
import { useParams } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import CultureBrief from 'components/CertificationHub/CultureBrief'
import {
  useUpdateCultureBriefMutation,
  useCertificationCultureBriefQuery,
  CultureBriefType,
  CurrentCertificationDocument,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'

const CultureBriefContainer = () => {
  const { uuid: certificationUuid } = useParams<{ uuid: string }>()
  const result = useCertificationCultureBriefQuery({
    variables: { certificationUuid },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  const [updateCultureBriefMutation] = useUpdateCultureBriefMutation()

  return (
    <ResponseHandler {...result}>
      {({ certification: { cultureBrief } }) => {
        const updateCultureBrief = async (
          update: Partial<CultureBriefType>,
          refetchCertification?: boolean,
        ) => {
          const response = await updateCultureBriefMutation({
            variables: {
              input: {
                id: cultureBrief.uuid,
                status: cultureBrief.status,
                ...update,
              },
            },
            refetchQueries: refetchCertification
              ? [{ query: CurrentCertificationDocument, variables: { skipCultureBrief: false } }]
              : [],
          })
          const errors = response.data?.updateCultureBrief?.errors
          if (!isNil(errors) && errors.length > 0) {
            emitter.emit('ERROR', errors.map(error => error?.messages).join('\n'))
            return false
          }
          return true
        }

        return (
          <CultureBrief cultureBrief={cultureBrief} onUpdateCultureBrief={updateCultureBrief} />
        )
      }}
    </ResponseHandler>
  )
}

export default CultureBriefContainer

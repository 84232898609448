import React, { useState, useEffect } from 'react'

import { GlobalNav, GlobalNavVariation } from '@activated-insights/global-nav'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { v4 as uuidv4 } from 'uuid'

import backgroundImage from './assets/login.png'
import emitter from './emitter'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import SnackbarNotifications from './SnackbarNotifications'
import Subscriptions from './Subscriptions'

import { gaEvent } from 'config/ga'
import { CurrentUserQuery } from 'generated/graphql'
import { GlobalNotification } from 'utils/types'

const SERVER_ERROR = 'There was an unexpected error. Please try again later'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  wrapper: {
    position: 'relative',
    top: 0,
    '@media screen': {
      height: '100vh',
    },
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
    /* Ensure background colors render in the print preview. */
    '-webkit-print-color-adjust': 'exact',
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [breakpoints.up('md')]: {
      width: `calc(100% - 260px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    paddingTop: '70px', // global nav height
    transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: spacing(2),
    padding: '0 15',
    minHeight: 'calc(100vh - 123px)',
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  container: {
    marginTop: spacing(2),
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: 'auto',
    marginLeft: 'auto',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
    [breakpoints.down('xs')]: {
      padding: spacing(),
    },
  },
  map: {
    marginTop: 70,
  },
  mainPanelSidebarMini: {
    [breakpoints.up('md')]: {
      width: `calc(100% - 80px)`,
    },
  },
  globalNav: {
    zIndex: 1033,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
}))

export type RouteType = {
  collapse?: boolean
  path: string
  hasAccess: boolean
  name?: string
  mini?: string
  subroutes?: RouteType[]
  teaser?: string
}

type Props = {
  user: CurrentUserQuery['currentUser']
  routes: RouteType[]
  footerRoutes: RouteType[]
}

const LayoutRouteContainer: React.FC<Props> = ({ user, routes, footerRoutes, children }) => {
  const classes = useStyles()

  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [isMiniActive, setIsMiniActive] = useState(false)
  const [notifications, setNotifications] = useState<GlobalNotification[]>([])
  const addNotification = (newNotif: Omit<GlobalNotification, 'id'>) => {
    setNotifications(currentNotifs => [...currentNotifs, { ...newNotif, id: uuidv4() }])
  }

  useEffect(() => {
    emitter.addListener('SUCCESS', (message: string, timeToClose?: number) => {
      addNotification({
        color: 'success',
        message,
        timeToClose,
      })
    })
    emitter.addListener('WARN', (message: string) => {
      addNotification({
        color: 'warning',
        message,
      })
    })
    emitter.addListener('ERROR', (message: string) => {
      addNotification({
        color: 'danger',
        message: message || SERVER_ERROR,
      })
    })

    // Clean when component is unmounted
    return () => {
      emitter.removeAllListeners('SUCCESS')
      emitter.removeAllListeners('WARN')
      emitter.removeAllListeners('ERROR')
    }
  })

  return (
    <div id="documentWrapper" className={classes.wrapper}>
      <div className={classes.globalNav}>
        <GlobalNav
          canAccessHcpExperience={false}
          canAccessHcpReputation={false}
          canAccessHcpTraining={false}
          variation={GlobalNavVariation.ACTIVATED_INSIGHTS}
          companyName={user.organization.name}
          userName={user.name}
          className={classes.globalNav}
        />
      </div>
      <Sidebar
        user={user}
        routes={routes}
        footerRoutes={footerRoutes}
        backgroundImage={backgroundImage}
        open={isMobileOpen}
        onClose={() => setIsMobileOpen(!isMobileOpen)}
        miniActive={isMiniActive}
      />
      <div
        className={cn(classes.mainPanel, { [classes.mainPanelSidebarMini]: isMiniActive })}
        id="perfectScrollbarContainer"
      >
        <Subscriptions userId={user.id} />
        <PerfectScrollbar>
          <Header
            sidebarMinimize={() => setIsMiniActive(!isMiniActive)}
            miniActive={isMiniActive}
            handleDrawerToggle={() => setIsMobileOpen(!isMobileOpen)}
          />
          <div className={classes.content}>
            <div className={classes.container}>{children}</div>
          </div>
          <Footer />
        </PerfectScrollbar>
      </div>
      <SnackbarNotifications
        notifications={notifications}
        closeNotification={notificationId => {
          setNotifications(currentNotifs => {
            return currentNotifs.filter(n => n.id !== notificationId)
          })
        }}
      />
    </div>
  )
}

export default LayoutRouteContainer

/**
 * Some components need to be manually resized on print.
 * Watch for the window print event and emit an app-specific event for
 * these components to listen to.
 *  */

if (window.matchMedia) {
  const mediaQueryList = window.matchMedia('print')
  mediaQueryList.addListener(mql => {
    if (mql.matches) {
      emitter.emit('BEFORE_PRINT')
      gaEvent({
        action: `printPage-${window.location.pathname.split('/').pop()}`,
        category: 'Global',
      })
    } else {
      emitter.emit('AFTER_PRINT')
    }
  })
}
window.onbeforeprint = () => emitter.emit('BEFORE_PRINT')
window.onafterprint = () => emitter.emit('AFTER_PRINT')

import React from 'react'

import { useApolloClient } from '@apollo/client'
import { Tooltip, Typography, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import InsightsOverviewRightDetail from 'components/Insights/Blocks/InsightsOverviewRightDetail'
import { ResponseRateTitleAndPie } from 'components/Insights/Blocks/ResponseRateTitleAndPie'
import TimeTrendingIcon from 'components/Insights/Blocks/TimeTrendingIcon'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  useInsightsEmployeeEligibilityResponseRateQuery,
  SurveyTypeEnum,
  CurrentUserDocument,
  CurrentUserQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { pluralize } from 'utils'
import { surveyPage, SURVEY_URLS } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  timeTrending: {
    position: 'absolute',
    right: 12,
    top: spacing(),
  },
}))

const getEligibilityMessage = (
  survey: InsightsSurvey,
  finished: number,
  certificationEligible: number,
  listEligible: number,
) => {
  let enoughMessageTitle
  let enoughMessage
  let color = colors.success
  const isGreat = finished >= listEligible
  const isGood = finished >= certificationEligible
  if (isGreat) {
    enoughMessageTitle = 'GREAT'
    enoughMessage = `These responses are strongly representative of all employees.`
  } else if (isGood) {
    enoughMessageTitle = 'GOOD'
    enoughMessage = `These responses are representative of all employees.`
  } else {
    const needCount = certificationEligible - finished
    color = colors.warning
    enoughMessageTitle = 'AIM FOR MORE'
    enoughMessage = `Aim for at least ${needCount} more ${pluralize(
      'response',
      certificationEligible - finished,
    )}${survey.endDate ? ' next time.' : '.'}`
  }
  if (finished < survey.minShowableResults) {
    color = colors.navy
  }
  return { enoughMessageTitle, enoughMessage, color }
}

type Props = {
  survey: InsightsSurvey
  finished: number
  total: number
  hasTimeTrending?: boolean
  filters: string[]
}

const EmployeeResponseRateCard: React.FC<Props> = ({
  survey,
  finished,
  total,
  hasTimeTrending,
  filters,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  const client = useApolloClient()
  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as NonNullable<
    CurrentUserQuery
  >
  const result = useInsightsEmployeeEligibilityResponseRateQuery({
    variables: { surveyUuid: survey.uuid, filters },
  })

  return (
    <ResponseHandler {...result}>
      {({ insightsEmployeeEligibilityResponseRate: { certificationEligible, listEligible } }) => {
        const { enoughMessageTitle, enoughMessage, color } = getEligibilityMessage(
          survey,
          finished,
          certificationEligible,
          listEligible,
        )
        const lessThanMin = finished < survey.minShowableResults
        const markupEnoughMessage = (
          <Typography variant="body2" color="textSecondary">
            {!lessThanMin && enoughMessage}
          </Typography>
        )
        return (
          <div id="response-rate-snap" className={classes.halfRoot}>
            <ResponseRateTitleAndPie
              surveyUuid={survey.uuid}
              total={total}
              finished={finished}
              minShowableResults={survey.minShowableResults}
            />
            <InsightsOverviewRightDetail
              title={
                lessThanMin
                  ? `Less than ${survey.minShowableResults} Responses`
                  : enoughMessageTitle
              }
              titleColor={color}
              score={
                lessThanMin
                  ? `<${survey.minShowableResults}`
                  : `${finished.toLocaleString()}/${total.toLocaleString()}`
              }
              extraDescription={
                <>
                  {survey.type === SurveyTypeEnum.TI ? (
                    <Tooltip title="To qualify for Great Place to Work Certification AND list consideration you need enough responses for a 95% confidence level and 5% margin of error.">
                      {markupEnoughMessage}
                    </Tooltip>
                  ) : (
                    markupEnoughMessage
                  )}
                  {currentUser.canCreateSurveys ? (
                    <NavLink to={surveyPage(survey.uuid, SURVEY_URLS.MONITOR)}>Show More</NavLink>
                  ) : (
                    <a href={survey.reportShareUrl} rel="noopener noreferrer" target="_blank">
                      <Typography>Show More</Typography>
                    </a>
                  )}
                </>
              }
            />
            {hasTimeTrending && (
              <TimeTrendingIcon
                chartKey={TimeTrendingChartKey.EMPLOYEE_INDEX_SCORE}
                className={classes.timeTrending}
              />
            )}
          </div>
        )
      }}
    </ResponseHandler>
  )
}
export default EmployeeResponseRateCard

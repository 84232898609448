import React, { useState, useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
} from '@material-ui/core'

import { Type, Notification, DisplayInfo, BooleanEnum } from './InformLettersEnum'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  CurrentUserDocument,
  CurrentUserQuery,
  DataTypeCode,
  FilterValueType,
  useOrganizationFilterValuesQuery,
} from 'generated/graphql'

const useStyles = makeStyles(({ palette, spacing }) => ({
  header: {
    marginBottom: spacing(3),
    color: palette.text.primary,
  },
  content: {
    textAlign: 'center',
    margin: '20px 0',
    color: palette.text.secondary,
  },
  formControl: {
    marginBottom: spacing(2),
    marginRight: spacing(3),
    alignItems: 'flex-start',
  },
  select: {
    minWidth: 200,
  },
  label: {
    marginBottom: spacing(1),
  },
  button: {
    marginRight: spacing(2),
    padding: spacing(1, 3),
  },
  disabledField: {
    backgroundColor: palette.action.disabledBackground,
    color: palette.text.disabled,
    borderColor: palette.action.disabled,
    cursor: 'not-allowed',
    opacity: 0.6,
  },
}))

type ControlProps = {
  onTypeChange: (type: Type) => void
  onLocationChange: (location: string) => void
  onLogoChange: (logo: BooleanEnum) => void
  onShowAddressChange: (address: BooleanEnum) => void
  onNotificationTypeChange: (notificationType: Notification) => void
  onDisplayMoreChange: (displayMore: DisplayInfo) => void
  onUseQrCodeChange: (useQrCode: BooleanEnum) => void
  setSelectedLocationDetails: (locationDetails: FilterValueType | null) => void
  defaultValues: {
    type: Type
    location: string
    logo: BooleanEnum
    showAddress: BooleanEnum
    notificationType: Notification
    displayMore: DisplayInfo
    useQrCode: BooleanEnum
  }
}

const InformLettersControl: React.FC<ControlProps> = ({
  onTypeChange,
  onLocationChange,
  onLogoChange,
  onShowAddressChange,
  onNotificationTypeChange,
  onDisplayMoreChange,
  onUseQrCodeChange,
  setSelectedLocationDetails,
  defaultValues,
}) => {
  const classes = useStyles()

  const [selectedLocation, setSelectedLocation] = useState(defaultValues.location)
  const [logoAvailable, setLogoAvailable] = useState(true)
  const [addressAvailable, setAddressAvailable] = useState(true)
  const [notificationType, setNotificationType] = useState(
    defaultValues.notificationType || Notification.DIGITAL,
  )
  const [
    selectedLocationDetails,
    setLocalSelectedLocationDetails,
  ] = useState<FilterValueType | null>(null)
  const client = useApolloClient()
  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as CurrentUserQuery

  const result = useOrganizationFilterValuesQuery({
    variables: { dtCode: DataTypeCode.AI_LOCATION },
  })

  useEffect(() => {
    if (selectedLocationDetails) {
      const hasLogo = !!selectedLocationDetails.logo
      setLogoAvailable(hasLogo)
      if (!hasLogo) {
        onLogoChange(BooleanEnum.FALSE)
      }
    } else {
      setLogoAvailable(true)
    }

    if (selectedLocationDetails) {
      const hasAddressLine1 = !!selectedLocationDetails.addressLine1
      setAddressAvailable(hasAddressLine1)
      if (!hasAddressLine1) {
        onShowAddressChange(BooleanEnum.FALSE)
      }
    } else {
      setAddressAvailable(true)
    }
  }, [selectedLocationDetails, onLogoChange, onShowAddressChange])

  useEffect(() => {
    if (!currentUser.organization.vicidialDid) {
      setNotificationType(Notification.DIGITAL)
      onNotificationTypeChange(Notification.DIGITAL)
    }
  }, [selectedLocationDetails, onNotificationTypeChange, currentUser.organization.vicidialDid])

  const handleLocationChange = (location: string) => {
    setSelectedLocation(location)
    onLocationChange(location)
  }

  const handleSetSelectedLocationDetails = (locationDetails: FilterValueType | null) => {
    setLocalSelectedLocationDetails(locationDetails)
    setSelectedLocationDetails(locationDetails)
    if (locationDetails && !locationDetails.addressLine1) {
      onShowAddressChange(BooleanEnum.FALSE)
      setAddressAvailable(false)
    } else {
      setAddressAvailable(true)
    }
    if (locationDetails && !locationDetails.logo) {
      onLogoChange(BooleanEnum.FALSE)
      setLogoAvailable(false)
    } else {
      setLogoAvailable(true)
    }
  }

  const handleNotificationTypeChange = (newNotificationType: Notification) => {
    setNotificationType(newNotificationType)
    onNotificationTypeChange(newNotificationType)
  }

  return (
    <ResponseHandler {...result}>
      {({ filterValues: locationFilterValues }) => (
        <div>
          <Typography variant="h4" className={classes.header}>
            Inform Letters
          </Typography>
          <Typography variant="h6" className={classes.content}>
            How to use Inform Letters
          </Typography>
          <Typography variant="body1" className={classes.content}>
            Download an inform letter to send to your customers notifying that they will be
            contacted by Activated Insights. This document will give a brief description of who will
            contact them and why.
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <Grid container spacing={2}>
              {/* Type */}
              <Grid item sm={6} md={4}>
                <Typography className={classes.label}>Type</Typography>
                <Select
                  value={defaultValues.type}
                  onChange={e => onTypeChange(e.target.value as Type)}
                  displayEmpty
                  className={classes.select}
                >
                  <MenuItem value={Type.CUSTOMER}>Inform letters - Customer</MenuItem>
                  <MenuItem value={Type.EMPLOYEE}>Inform letters - Employee</MenuItem>
                </Select>
              </Grid>
              {/* Location */}
              <Grid item sm={6} md={4}>
                <Typography className={classes.label}>Location</Typography>
                <Select
                  value={selectedLocation}
                  onChange={e => handleLocationChange(e.target.value as string)}
                  displayEmpty
                  className={`${classes.select} ${
                    !locationFilterValues.length ? classes.disabledField : ''
                  }`}
                  disabled={!locationFilterValues.length}
                >
                  <MenuItem value="" disabled>
                    Location
                  </MenuItem>
                  {locationFilterValues.map(location => (
                    <MenuItem
                      onClick={() => handleSetSelectedLocationDetails(location as FilterValueType)}
                      key={location.uuid}
                      value={location.name}
                    >
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {/* Logo */}
              <Grid item sm={6} md={4}>
                <Typography className={classes.label}>Logo</Typography>
                <Select
                  value={defaultValues.logo}
                  onChange={e => onLogoChange(e.target.value as BooleanEnum)}
                  displayEmpty
                  className={`${classes.select} ${!logoAvailable ? classes.disabledField : ''}`}
                  disabled={!logoAvailable}
                >
                  <MenuItem value={BooleanEnum.TRUE}>On</MenuItem>
                  <MenuItem value={BooleanEnum.FALSE}>Off</MenuItem>
                </Select>
              </Grid>
              {/* Address */}
              <Grid item sm={6} md={4}>
                <Typography className={classes.label}>Address</Typography>
                <Select
                  value={defaultValues.showAddress}
                  onChange={e => onShowAddressChange(e.target.value as BooleanEnum)}
                  displayEmpty
                  className={`${classes.select} ${!addressAvailable ? classes.disabledField : ''}`}
                  disabled={!addressAvailable}
                >
                  <MenuItem value={BooleanEnum.TRUE}>On</MenuItem>
                  <MenuItem value={BooleanEnum.FALSE}>Off</MenuItem>
                </Select>
              </Grid>
              {/* Notification */}
              <Grid item sm={6} md={4}>
                <Typography className={classes.label}>Notification</Typography>
                <Select
                  value={notificationType}
                  onChange={e => handleNotificationTypeChange(e.target.value as Notification)}
                  displayEmpty
                  className={`${classes.select} ${
                    !currentUser.organization.vicidialDid ? classes.disabledField : ''
                  }`}
                  disabled={!currentUser.organization.vicidialDid}
                >
                  <MenuItem
                    value={Notification.PHONE}
                    disabled={!currentUser.organization.vicidialDid}
                  >
                    Phone
                  </MenuItem>
                  <MenuItem value={Notification.DIGITAL}>Digital</MenuItem>
                  <MenuItem
                    value={Notification.PHONE_AND_DIGITAL}
                    disabled={!currentUser.organization.vicidialDid}
                  >
                    Phone and Digital
                  </MenuItem>
                </Select>
              </Grid>
              {/* Display Info */}
              <Grid item sm={6} md={4}>
                <Typography className={classes.label}>Display Info</Typography>
                <Select
                  value={defaultValues.displayMore}
                  onChange={e => onDisplayMoreChange(e.target.value as DisplayInfo)}
                  displayEmpty
                  className={classes.select}
                >
                  <MenuItem value={DisplayInfo.LESS}>Less</MenuItem>
                  <MenuItem value={DisplayInfo.MORE}>More</MenuItem>
                </Select>
              </Grid>
              {/* QR Code */}
              <Grid item sm={6} md={4}>
                <Typography className={classes.label}>QR Code</Typography>
                <Select
                  value={defaultValues.useQrCode}
                  onChange={e => onUseQrCodeChange(e.target.value as BooleanEnum)}
                  displayEmpty
                  className={classes.select}
                >
                  <MenuItem value={BooleanEnum.FALSE}>Off</MenuItem>
                  <MenuItem value={BooleanEnum.TRUE}>On</MenuItem>
                </Select>
              </Grid>
              {/* Buttons */}
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => window.print()}
                      className={classes.button}
                      disabled={!selectedLocation}
                    >
                      Print/Download
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </div>
      )}
    </ResponseHandler>
  )
}

export default InformLettersControl

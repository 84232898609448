import React, { useState } from 'react'

import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import UpdateUser, { UserDialogSteps } from 'components/Settings/Team/Users/UpdateUser'
import { UserData } from 'components/Settings/Team/Users/Users'
import { GroupNode, useSettingsValidGroupsQuery } from 'generated/graphql'
import { SettingsUser } from 'utils/types'

const useStyles = makeStyles(() => ({
  dialog: {
    width: 500,
  },
}))

type Props = {
  onUpdateUser(user: SettingsUser): Promise<boolean>
  onClose(): void
  userToUpdate?: UserData
  step: UserDialogSteps
  loading: boolean
}

const UpdateUserContainer: React.FC<Props> = ({
  userToUpdate,
  onUpdateUser,
  onClose,
  step: initialStep,
  loading,
}) => {
  const classes = useStyles()
  const result = useSettingsValidGroupsQuery()

  const [step, setStep] = useState(initialStep)

  let title = 'Set Data Access'
  if (step === UserDialogSteps.USER_INFO) {
    if (userToUpdate) {
      title = 'Update User'
    } else {
      title = 'Add User'
    }
  }

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="user-dialog-title"
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <DialogTitle id="user-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <ResponseHandler {...result}>
          {({ validGroups }) => {
            return (
              <UpdateUser
                setStep={setStep}
                validGroups={(validGroups as GroupNode[]) || []}
                onUpdateUser={onUpdateUser}
                loading={loading}
                onClose={onClose}
                userToUpdate={userToUpdate}
                step={step}
              />
            )
          }}
        </ResponseHandler>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateUserContainer

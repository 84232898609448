import React, { useState } from 'react'

import { makeStyles, MenuItem } from '@material-ui/core'

import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import {
  ALL_RESIDENT_LEFT_REASONS_COLOR_MAPPING,
  ChartType,
  CHART_TYPE_TO_LABEL,
  getVoluntaryCountsFromReasonsForLeaving,
} from 'components/Insights/Blocks/ReasonsForLeavingCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import {
  ChartTimeRange,
  timeRangeDescription,
} from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import TTGroupedScoresChart from 'components/Insights/TimeTrending/TTGroupedScoresChart'
import {
  ScoreBySurvey,
  SurveyScoreByGroup,
  transformGroupedScoresToEcharts,
} from 'components/Insights/TimeTrending/utils'
import { BenchmarkCodeType, useInsightsMultiselectGroupCountsQuery } from 'generated/graphql'
import DynamicTimeframeQuery from 'HOC/DynamicTimeframeQuery'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
  },
  chart: {
    marginTop: spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoPercentageText: {
    '& >span': {
      marginLeft: -14,
    },
  },
}))

type Props = {
  survey: InsightsSurvey
  filters: string[]
  timeRanges: ChartTimeRange[]
}
const TimeframeReasonsForLeavingCard: React.FC<Props> = ({ survey, filters, timeRanges }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const [chartType, setChartType] = useState(ChartType.ALL_REASONS)
  return (
    <div className={classes.fullRow} id="timeframe-reasons-for-leaving-snap">
      <SnapshotChartHeader
        title="Compare Why Residents are Leaving Over Time"
        tooltip="See how the # of responses for each reason for leaving has changed over time."
        snapId="timeframe-reasons-for-leaving-snap"
        useBottomPadding={false}
        description={timeRangeDescription(timeRanges)}
        extraControls={
          <TextDropdown
            id="reasons-for-leaving-dropdown"
            value={chartType}
            renderValue={type => `Show: ${CHART_TYPE_TO_LABEL[type as ChartType]}`}
            onChange={e => {
              setChartType(e.target.value as ChartType)
            }}
          >
            <MenuItem value={ChartType.ALL_REASONS}>
              {CHART_TYPE_TO_LABEL[ChartType.ALL_REASONS]}
            </MenuItem>
            <MenuItem value={ChartType.VOLUNTARY_INVOLUNTARY}>
              {CHART_TYPE_TO_LABEL[ChartType.VOLUNTARY_INVOLUNTARY]}
            </MenuItem>
          </TextDropdown>
        }
      />
      <DynamicTimeframeQuery
        timeRanges={timeRanges}
        variables={{
          filters,
          surveyUuid: survey.uuid,
          multiselectCode: BenchmarkCodeType.LEFT_REASON,
        }}
        queryHook={useInsightsMultiselectGroupCountsQuery}
      >
        {data => {
          const scoresByTimeRange: SurveyScoreByGroup = {}
          let orderedGroupNames = survey.reasonsForLeaving.map(rl => rl.value)
          let colorList = Object.values(ALL_RESIDENT_LEFT_REASONS_COLOR_MAPPING)
          if (chartType === ChartType.VOLUNTARY_INVOLUNTARY) {
            data.forEach(({ insightsMultiselectGroupCounts: groups, uuid }) => {
              const {
                voluntaryCount,
                involuntaryCount,
                total,
              } = getVoluntaryCountsFromReasonsForLeaving({
                groups,
                reasonsForLeaving: survey.reasonsForLeaving,
              })
              if (!scoresByTimeRange.Voluntary) {
                scoresByTimeRange.Voluntary = {} as ScoreBySurvey
                scoresByTimeRange.Involuntary = {} as ScoreBySurvey
              }
              // If total count is 0, there were no responses in this group, so we can set
              // total to 1 to avoid divide-by-zero errors.
              const totalCount = total || 1
              scoresByTimeRange.Voluntary[uuid] = (voluntaryCount / totalCount) * 100
              scoresByTimeRange.Involuntary[uuid] = (involuntaryCount / totalCount) * 100
            })
            orderedGroupNames = ['Voluntary', 'Involuntary']
            colorList = ['#5B70C0', '#7BD574']
          } else {
            data.forEach(({ insightsMultiselectGroupCounts: groups, uuid }) => {
              // If total count is 0, there were no responses in this group, so we can set
              // total to 1 to avoid divide-by-zero errors.
              const totalCount = groups.reduce((acc, group) => acc + group.count, 0) || 1
              survey.reasonsForLeaving.forEach(rl => {
                if (!scoresByTimeRange[rl.value]) {
                  scoresByTimeRange[rl.value] = {} as ScoreBySurvey
                }
                scoresByTimeRange[rl.value][uuid] =
                  ((groups.find(g => g.label === rl.value)?.count || 0) / totalCount) * 100
              })
            })
          }
          const groupedScores = transformGroupedScoresToEcharts(
            orderedGroupNames,
            scoresByTimeRange,
            data.map(({ uuid }) => uuid),
          )
          return (
            <div className={classes.chart}>
              <TTGroupedScoresChart
                xAxisLabelData={timeRanges}
                groupedScores={groupedScores}
                benchmarkName="Company Overall"
                minShowableResults={survey.minShowableResults}
                title="Reasons for Leaving"
                didSelectFilters={false}
                orderedGroupNames={orderedGroupNames}
                surveyScoresByGroup={scoresByTimeRange}
                colorList={colorList}
                tooltipColorList={[colors.navy]}
                maxLabelLength={25}
              />
            </div>
          )
        }}
      </DynamicTimeframeQuery>
    </div>
  )
}

export default TimeframeReasonsForLeavingCard

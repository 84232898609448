import theme, { CommonColorsAlias, pieChartColors } from './theme'
import withMuiTheme from './withMuiTheme'

// Module extension to allow accessing our custom properties on the theme object.
declare module '@material-ui/core/colors/common' {
  interface CommonColors extends CommonColorsAlias {}
}

export type AICommonColors = typeof theme.palette.common
const colors: AICommonColors = theme.palette.common

export type AITheme = typeof theme

export { colors, withMuiTheme, pieChartColors }
export default theme

import React from 'react'

import { Typography } from '@material-ui/core'

import Accordion from 'components/Blocks/Accordion/Accordion'
import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import Checkbox from 'components/Blocks/Checkboxes/Checkbox'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import {
  BenchmarkCodeType,
  SurveysSurveyQuery,
  SurveysQuestionsQuery,
  useSurveysAvailableSurveyQuestionsQuery,
  useSurveysBulkSetQuestionMutation,
  SurveysQuestionsDocument,
  QCategory,
  NpsGroupsEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import { getNpsAbbreviation, getNpsLabel } from 'utils'

export const NPS_GROUP_TO_LABEL = {
  [NpsGroupsEnum.PROMOTERS]: 'Promoters',
  [NpsGroupsEnum.PASSIVES]: 'Passives',
  [NpsGroupsEnum.DETRACTORS]: 'Detractors',
}
export const NPS_THRESHOLDS = {
  [NpsGroupsEnum.PROMOTERS]: [9, 10],
  [NpsGroupsEnum.PASSIVES]: [7, 8],
  [NpsGroupsEnum.DETRACTORS]: [0, 6],
}
export const NPS_QUESTION_CODES = [
  BenchmarkCodeType.NPS_RECOMMEND,
  BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP,
]

type Props = {
  survey: SurveysSurveyQuery['survey']
  surveyQuestions: SurveysQuestionsQuery['questions']
}

const NpsQuestions: React.FC<Props> = ({ survey, surveyQuestions }) => {
  const [bulkSetQuestions] = useSurveysBulkSetQuestionMutation()
  const result = useSurveysAvailableSurveyQuestionsQuery({
    variables: {
      surveyUuid: survey.uuid,
    },
  })

  const onSubmit = async (selectedQuestionUuids: string[], unselectedQuestionUuids: string[]) => {
    const setResult = await bulkSetQuestions({
      variables: {
        surveyUuid: survey.uuid,
        selectedQuestionUuids,
        unselectedQuestionUuids,
      },
      refetchQueries: [
        {
          query: SurveysQuestionsDocument,
          variables: {
            surveyUuid: survey.uuid,
          },
        },
      ],
    })
    const errors = setResult.data?.bulkSetSurveyQuestion?.errors
    if (errors) {
      emitter.emit('ERROR', errors)
    }
  }

  const getCategoryLabel = (questionCategory?: QCategory | null) => {
    if (questionCategory === QCategory.OPEN_ENDED) {
      return 'Open Ended Question'
    }
    return 'Linear Scale Question'
  }

  return (
    <ResponseHandler {...result}>
      {({ availableSurveyQuestions }) => {
        const npsQuestionIsInSurvey = surveyQuestions.some(
          question => question.benchmarkCode && NPS_QUESTION_CODES.includes(question.benchmarkCode),
        )
        const npsAvailableQuestions = availableSurveyQuestions.filter(
          question => question.benchmarkCode && NPS_QUESTION_CODES.includes(question.benchmarkCode),
        )
        const npsQuestionsUuids = npsAvailableQuestions.map(q => q.uuid)
        const npsIsRequired = availableSurveyQuestions.some(
          question =>
            question.benchmarkCode &&
            NPS_QUESTION_CODES.includes(question.benchmarkCode) &&
            question.required,
        )
        if (!npsAvailableQuestions.length) {
          return <></>
        }
        return (
          <FormPanel
            removeDetailsPadding
            expandIcon={EXPAND_ICON.ARROW}
            square
            summary={
              <GridContainer>
                <ItemGrid sm={4}>
                  <Typography variant="h6">{getNpsLabel(survey.productType, false)}</Typography>
                </ItemGrid>
                <ItemGrid sm={8}>
                  <Typography color="textSecondary">
                    Collect {getNpsLabel(survey.productType)} to measure the willingness of your
                    {survey.productType === SurveyProductTypeEnum.EMPLOYEE
                      ? ' employees'
                      : ' customers'}{' '}
                    to recommend your services to others.
                  </Typography>
                </ItemGrid>
              </GridContainer>
            }
          >
            <Accordion
              collapses={[
                {
                  title: `${getNpsLabel(survey.productType)} Questions`,
                  content: (
                    <>
                      {npsAvailableQuestions.map((question, index) => (
                        <Typography key={question.uuid}>
                          {index + 1}. ({getCategoryLabel(question.category)}) {question.text}
                        </Typography>
                      ))}
                      {!npsIsRequired && (
                        <Checkbox
                          checked={npsQuestionIsInSurvey}
                          onChange={() =>
                            onSubmit(
                              npsQuestionIsInSurvey ? [] : npsQuestionsUuids,
                              npsQuestionIsInSurvey ? npsQuestionsUuids : [],
                            )
                          }
                          label={`Yes, add ${getNpsAbbreviation(survey.productType)} questions`}
                        />
                      )}
                    </>
                  ),
                },
              ]}
            />
          </FormPanel>
        )
      }}
    </ResponseHandler>
  )
}

export default NpsQuestions

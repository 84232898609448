export const verifyEmail = (value: string) => {
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRex.test(value)
}

export const verifyLength = (value: string, lengthMin: number, lengthMax?: number) => {
  if (lengthMax === undefined) {
    return value.length >= lengthMin
  }

  return lengthMax >= value.length && value.length >= lengthMin
}

export const verifyZipcode = (value: string, required: boolean) => {
  if (!required && !value) {
    return true
  }
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
}

export const verifyUrl = (value: string) => {
  if (!value) return true
  // Regex source: https://dev.to/davidemaye/how-to-validate-urls-in-javascript-adm
  // eslint-disable-next-line
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  )
  return pattern.test(value)
}

import React, { ReactElement, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  makeStyles,
  Button as MUIButton,
  AppBar,
  Paper,
  Tab,
  Tabs,
  Typography,
  Card,
} from '@material-ui/core'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import WarningIcon from '@material-ui/icons/Warning'
import cn from 'classnames'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useHistory, NavLink } from 'react-router-dom'

import { ReactComponent as NotCertifiedMessage } from 'assets/img/certification_hub/not-certified-message.svg'
import { ReactComponent as CheckBackLaterIcon } from 'assets/img/check-back-later-icon.svg'
import { ReactComponent as CompletedCircleIcon } from 'assets/img/completed-status-circle-icon.svg'
import { ReactComponent as WarningCircleIcon } from 'assets/img/warning-circle-icon.svg'
import Button from 'components/Blocks/CustomButtons/Button'
import Page from 'components/Blocks/Layout/Page'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import StatusTag from 'components/Blocks/Tags/StatusTag'
import CertificationHubBanner from 'components/CertificationHub/CertificationHubBanner'
import CertificationModalBanner from 'components/CertificationHub/CertificationModalBanner'
import CertificationsTable from 'components/CertificationHub/CertificationsTable'
import CertifiedView from 'components/CertificationHub/CertifiedView'
import MarketingToolkitView from 'components/CertificationHub/MarketingToolkitView'
import PastCertificationsView from 'components/CertificationHub/PastCertificationsView'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import { gaEvent } from 'config/ga'
import {
  CurrentCertificationQuery,
  InsightsModulesEnum,
  SurveyProductTypeEnum,
  SurveyStatusEnum,
  useCurrentCertificationQuery,
  useUpdateCultureBriefMutation,
  CurrentUserDocument,
  CurrentUserQuery,
  DataTypeCode,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { pluralize } from 'utils'
import { SUPPORT_EMAIL, surveyPage, SURVEY_URLS, URLS, filterValuesPage } from 'utils/constants'
import { usePublicConstants } from 'utils/customHooks'
import {
  CertificationStatusEnum,
  CultureBriefStatusEnum,
  UserAppMessageEnum,
} from 'utils/generatedEnums'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  header: {
    marginTop: 12,
    backgroundColor: palette.common.navy05,
    paddingLeft: spacing(2),
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
  },
  mainBlock: {
    padding: spacing(3),
    paddingTop: spacing(4),
    '& >div': {
      display: 'flex',
    },
  },
  statusRow: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      marginLeft: spacing(),
    },
    height: 40,
  },
  card: {
    position: 'relative',
    paddingTop: spacing(2),
    marginTop: spacing(2),
    border: `1px solid ${palette.common.navy15}`,
    '&:nth-child(1)': {
      borderRight: 0,
    },
    width: '50%',
    '& >p': {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      '& >span': {
        color: palette.common.navy65,
      },
    },
  },
  actionButton: {
    borderRadius: 0,
    backgroundColor: palette.common.secondary20,
    marginTop: spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    '& >span>svg': {
      color: palette.common.navy65,
    },
    paddingLeft: spacing(3),
  },
  confirmed: {
    backgroundColor: palette.common.secondarySuccess,
  },
  successText: {
    color: palette.common.success,
  },
  dangerText: {
    color: palette.common.danger,
  },
  notCertified: {
    backgroundColor: palette.common.secondaryWarning,
  },
  circleIcon: {
    position: 'absolute',
    top: spacing(2),
    right: spacing(3),
  },
  notCertifiedTextBlock: {
    padding: spacing(2),
    backgroundColor: palette.common.navy05,
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(6),
    '& >svg': {
      width: 800,
      marginRight: spacing(3),
    },
  },
  notCertifiedScoreBlock: {
    '& >p': {
      color: palette.common.navy,
    },
  },
  borderBox: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    borderTop: `1px solid ${palette.common.navy15}`,
    borderBottom: `1px solid ${palette.common.navy15}`,
  },
  title: {
    paddingBottom: spacing(),
  },
  textWithInlineButton: {
    display: 'flex',
    alignItems: 'center',
    '& >button': {
      padding: 0,
      margin: 0,
    },
  },
  tableContainer: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    borderTop: `1px solid ${palette.common.navy25}`,
  },
  locationsBanner: {
    alignItems: 'center',
    padding: spacing(),
    marginBottom: spacing(3),
    backgroundColor: palette.common.secondaryWarning,
    '& >svg': {
      color: palette.common.warning,
      marginRight: spacing(),
    },
  },
}))

export const CertificationMessageContentKey = 'certifications-surveycertification'

// Currently rendered during "In Progress" or "Not Certified" states.
const BaseView: React.FC<CurrentCertificationQuery['certification'] & {
  currentUser: CurrentUserQuery['currentUser']
  viewLimitedTo?: 'locations' | 'organization'
}> = ({
  currentUser,
  survey: {
    insightsSurvey: { overallIndexScore, responseRate },
  },
  status: certificationStatus,
  survey,
  cultureBrief,
  organizationName,
  locationCertifications,
  uuid,
  passesScoreCriteria,
  passesResponseRateCriteria,
  viewLimitedTo,
}) => {
  const notCertified =
    (viewLimitedTo !== 'locations' &&
      certificationStatus === CertificationStatusEnum.NOT_CERTIFIED) ||
    (viewLimitedTo === 'locations' &&
      locationCertifications.filter(locCert => locCert.isCertified).length === 0)
  const { trustIndexCertificationMinimumScore } = usePublicConstants()
  const [updateCultureBrief] = useUpdateCultureBriefMutation()
  const classes = useStyles()
  const history = useHistory()
  // Defaults for closed survey
  const insightsUrl = getInsightsPage(
    survey.uuid,
    InsightsModulesEnum.SNAPSHOTS,
    SurveyProductTypeEnum.EMPLOYEE,
  )
  let surveyUrl = insightsUrl
  let surveyActionText = 'See Survey Results'
  if (survey.status !== SurveyStatusEnum.CLOSED) {
    let wizardTab = SURVEY_URLS.DESIGN
    if (survey.status === SurveyStatusEnum.SCHEDULED) {
      wizardTab = SURVEY_URLS.SUMMARY
    } else if (survey.status === SurveyStatusEnum.LIVE) {
      wizardTab = SURVEY_URLS.MONITOR
    }
    surveyUrl = surveyPage(survey.uuid, wizardTab)
    surveyActionText = 'See Survey Results'
  }

  let surveyDescription:
    | string
    | ReactElement = `You will need a qualifying response rate, and TIS score of ${trustIndexCertificationMinimumScore}% or higher to be Great Place to Work-Certified™.`
  let cultureBriefActionText = 'Start Your Culture Brief'
  let cultureBriefDescription =
    'Complete the Culture Brief questionnaire about your company values, benefits and employee demographics.'
  if (cultureBrief.status === CultureBriefStatusEnum.IN_PROGRESS) {
    cultureBriefActionText = 'Complete Your Culture Brief'
  } else if (cultureBrief.status === CultureBriefStatusEnum.COMPLETED) {
    cultureBriefActionText = 'Edit Your Online Profile'
    cultureBriefDescription =
      'Thank you for completing the Culture Brief questionnaire about your company values, benefits and employee demographics.'
  }

  if (notCertified) {
    surveyDescription = (
      <div className={classes.notCertifiedScoreBlock}>
        <Typography>
          Trust Index Score:&nbsp;
          <span className={passesScoreCriteria ? classes.successText : classes.dangerText}>
            {Math.round(Number(overallIndexScore?.positive))}%
          </span>
        </Typography>
        <Typography>
          Responses:&nbsp;
          <span className={passesResponseRateCriteria ? classes.successText : classes.dangerText}>
            {responseRate.finished}/{responseRate.total} Participants (
            {Math.round(responseRate.rate)}%)
          </span>
        </Typography>
        <br />
      </div>
    )
    cultureBriefActionText = 'View Your Culture Brief'
  }
  return (
    <>
      {/** TODO: add these back when design is finished */}
      {/* {now >= subMonths(new Date(accessEndDate), 1) && (
        <CertificationHubBanner
          title={`You must complete all required submissions before ${formattedAccessEndDate}`}
          description="TODO FILL OUT"
          status="expiresSoon"
          formattedExpirationDate={getFormattedSurveyDate({
            date: accessEndDate,
            includeDay: true,
            excludeComma: false,
          })}
        />
      )}
      {now >= subMonths(new Date(fortuneListSubmissionDate), 1) && (
        <CertificationHubBanner
          title={`You must complete all required submissions before ${formattedFortuneDeadline}  to be considered for Fortune’s 2023 Aging Services list.`}
          description="TODO FILL OUT"
          status="expiresSoon"
          formattedExpirationDate={getFormattedSurveyDate({
            date: fortuneListSubmissionDate,
            includeDay: true,
            excludeComma: false,
          })}
        />
      )} */}
      <div className={classes.mainBlock}>
        {survey.numberOfLocationFilterValuesMissingRequiredData > 0 && (
          <Paper elevation={0} className={classes.locationsBanner}>
            <WarningIcon />
            <Typography variant="subtitle2">
              {survey.numberOfLocationFilterValuesMissingRequiredData}{' '}
              {pluralize('location', survey.numberOfLocationFilterValuesMissingRequiredData)}{' '}
              {survey.numberOfLocationFilterValuesMissingRequiredData === 1 ? 'is' : 'are'} missing
              the required state and zip code data.
            </Typography>
            &nbsp;
            <NavLink
              to={filterValuesPage(
                currentUser.filters.find(
                  filterType => filterType.dtCode === DataTypeCode.AI_LOCATION,
                )?.filterTypeUuid || '',
              )}
            >
              Add Location Data
            </NavLink>
          </Paper>
        )}
        {notCertified && (
          <div className={classes.notCertifiedTextBlock}>
            <NotCertifiedMessage />
            <Typography>
              What now? It’s time to look at your results to understand why you didn’t certify. Your
              Trust Index results will help you understand where there are opportunities to improve
              your employee experience. It’s normal to have to survey multiple times before
              successfully certifying. Reach out to our Customer Support team for more tailored
              feedback about how to get the most out of your results.{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>Get in Touch</a>
            </Typography>
          </div>
        )}
        <Typography>REQUIRED SUBMISSIONS</Typography>
        <div>
          <div className={classes.card}>
            <Typography>{survey.name}</Typography>
            {survey.status === SurveyStatusEnum.CLOSED && (
              <>
                {notCertified ? (
                  <WarningCircleIcon className={classes.circleIcon} />
                ) : (
                  <CompletedCircleIcon className={classes.circleIcon} />
                )}
              </>
            )}
            <Typography className={classes.statusRow}>
              Status: <StatusTag status={survey.status} />
            </Typography>
            <Typography color="textSecondary">{surveyDescription}</Typography>
            <MUIButton
              fullWidth
              className={cn({
                [classes.actionButton]: true,
                [classes.confirmed]: survey.status === SurveyStatusEnum.CLOSED && !notCertified,
                [classes.notCertified]: survey.status === SurveyStatusEnum.CLOSED && notCertified,
              })}
              onClick={() => history.push(surveyUrl)}
            >
              {surveyActionText}
              <ArrowRight />
            </MUIButton>
          </div>
          <div className={classes.card}>
            <Typography>Your Culture Brief</Typography>
            {cultureBrief.status === CultureBriefStatusEnum.COMPLETED && (
              <CompletedCircleIcon className={classes.circleIcon} />
            )}
            <Typography className={classes.statusRow}>
              Status:&nbsp;
              <span>{cultureBrief.status || 'Not Started'}</span>
            </Typography>
            <Typography color="textSecondary">{cultureBriefDescription}</Typography>
            <MUIButton
              fullWidth
              className={cn({
                [classes.actionButton]: true,
                [classes.confirmed]: cultureBrief.status === CultureBriefStatusEnum.COMPLETED,
              })}
              onClick={async () => {
                if (cultureBrief.status === CultureBriefStatusEnum.NOT_STARTED) {
                  await updateCultureBrief({
                    variables: {
                      input: { id: cultureBrief.uuid, status: CultureBriefStatusEnum.IN_PROGRESS },
                    },
                  })
                }
                history.push(`${URLS.CERTIFICATION_HUB.CULTURE_BRIEF}/${uuid}`)
              }}
            >
              {cultureBriefActionText}
              <ArrowRight />
            </MUIButton>
          </div>
        </div>
      </div>
      {notCertified && (
        <>
          <div className={classes.tableContainer}>
            <CertificationsTable
              orgIsCertified={false}
              organizationName={organizationName}
              locationCertifications={locationCertifications}
              // When org is single-site, limit to the org-only view on this table.
              viewLimitedTo={locationCertifications.length === 1 ? 'organization' : viewLimitedTo}
              surveyUuid={survey.uuid}
            />
          </div>
          <div className={classes.mainBlock}>
            <Typography className={classes.title}>HELPFUL RESOURCES</Typography>
            <Typography color="textSecondary">
              Get the most out of your certification process to help increase your likelihood of
              getting certified next time.{' '}
            </Typography>
            <div className={classes.borderBox}>
              <Typography>Learn more about improving Trust Index Scores</Typography>
            </div>
            <Typography color="textSecondary">
              A great workplace is one where you TRUST the people you work for; have PRIDE in what
              you do; and ENJOY the people you work with. We measured how your employees feel across
              the dimensions that build trust. Use your results to understand where your strengths
              and gaps are to better understand what employees would like for you to work on.
              <br />
              <ul>
                <li>
                  <div className={classes.textWithInlineButton}>
                    Look at the&nbsp;
                    <Button color="secondaryNoBackground" onClick={() => history.push(insightsUrl)}>
                      Snapshot Tab
                    </Button>
                    &nbsp;- look for your top statements and areas for opportunity.
                  </div>
                </li>
                <li>Talk to employees to better understand what is driving your low scores</li>
                <li>Use benchmarks to see how you compare to your peers</li>
                <li>Review your results with your Program Manager</li>
              </ul>
            </Typography>
            <Button
              onClick={() => {
                window.open(
                  'https://activatedinsights.force.com/help/s/article/Tips-to-Improve-your-Trust-Index-Scores',
                  '_blank',
                )
              }}
            >
              Read the Resource Article
            </Button>
            <div className={classes.borderBox}>
              <Typography>Learn more about improving Response Rates</Typography>
            </div>
            <Typography color="textSecondary">
              The best way to help ensure you get certified is to make sure you get a high response
              rate by planning out a strategy before the survey launches.
            </Typography>
            <br />
            <Button
              onClick={() => {
                window.open(
                  'https://activatedinsights.force.com/help/s/article/drivingparticipation',
                  '_blank',
                )
              }}
            >
              Read the Resource Article
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export enum CertificationHubTab {
  HUB = 'Certification Hub',
  MARKETING = 'Marketing Toolkit',
  PAST_CERTIFICATIONS = 'Past Certifications',
}
const CertificationHubContainer: React.FC = () => {
  const client = useApolloClient()
  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as NonNullable<
    CurrentUserQuery
  >
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(CertificationHubTab.HUB)
  const result = useCurrentCertificationQuery({
    variables: {
      skipCultureBrief: !currentUser.canManageCertifications,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({
        certification,
        currentUser: {
          organization: { certifications: allCertifications },
        },
      }) => {
        if (!certification) {
          return (
            <Card>
              <EmptyState
                title="Check Back Later"
                description="Your Great Place to Work Certification™ isn't available yet. It will appear here as soon as it's ready."
                Icon={CheckBackLaterIcon}
              />
            </Card>
          )
        }
        let viewLimitedTo: 'locations' | 'organization' | undefined
        // If org does not have access to location data, show an org-limited view
        if (certification.locationCertifications.length === 0) {
          viewLimitedTo = 'organization'
        }
        // If user does not have access to all locations, show a location-limited view.
        else if (
          !currentUser.filters.some(ft => ft.dtCode === DataTypeCode.AI_LOCATION && ft.accessToAll)
        ) {
          viewLimitedTo = 'locations'
        }
        const tabs = [CertificationHubTab.HUB]
        if (
          certification.status === CertificationStatusEnum.CERTIFIED ||
          (certification.status === CertificationStatusEnum.NOT_CERTIFIED &&
            certification.locationCertifications.length > 0)
        ) {
          tabs.push(CertificationHubTab.MARKETING)
        }
        const now = new Date()

        const expiredCertifications = allCertifications.filter(
          cert =>
            // Certifications that were "finished" and access has ended.
            cert.status !== CertificationStatusEnum.IN_PROGRESS &&
            new Date(cert.accessEndDate) < now,
        )

        if (expiredCertifications.length > 0) {
          tabs.push(CertificationHubTab.PAST_CERTIFICATIONS)
        }
        const hasSeenAppMessage = currentUser.appMessages.some(appMessage => {
          return (
            appMessage.kind === UserAppMessageEnum.SURVEY_CERTIFICATION &&
            appMessage.contentObjects.find(
              co =>
                co.contentType === CertificationMessageContentKey && co.uuid === certification.uuid,
            )
          )
        })
        const orgCertified = certification.status === CertificationStatusEnum.CERTIFIED
        const cultureBriefCompleted =
          certification.cultureBrief.status === CultureBriefStatusEnum.COMPLETED
        const locationCertified =
          certification.locationCertifications.filter(loc => loc.isCertified).length > 0
        let ViewComponent = null
        if (currentTab === CertificationHubTab.HUB) {
          let CertificationView = (
            <BaseView currentUser={currentUser} {...certification} viewLimitedTo={viewLimitedTo} />
          )
          if (!certification.survey.numberOfLocationFilterValuesMissingRequiredData) {
            if (
              (viewLimitedTo !== 'locations' && orgCertified) ||
              (cultureBriefCompleted && locationCertified)
            ) {
              CertificationView = (
                <CertifiedView
                  onUpdateTab={setCurrentTab}
                  certification={certification}
                  viewLimitedTo={viewLimitedTo}
                />
              )
            } else if (
              certification.status === CertificationStatusEnum.CERTIFIED_EXPIRED ||
              certification.status === CertificationStatusEnum.NOT_CERTIFIED_EXPIRED ||
              certification.status === CertificationStatusEnum.ACCESS_EXPIRED
            ) {
              CertificationView = (
                <>
                  <CertificationHubBanner
                    title="Get Certified™ Today"
                    description="Become Great Place to Work-Certified™ to attract top talent, build your employer brand and deliver business results."
                    buttonLabel="Sign Up Today"
                    backgroundColor={colors.secondaryWarning}
                    url="https://activatedinsights.com/product/certify/"
                  />
                  <br />
                </>
              )
            }
          }
          ViewComponent = (
            <>
              <div className={classes.header}>
                <Typography variant="h6">Employee Great Place to Work Certification</Typography>
              </div>
              {CertificationView}
            </>
          )
        } else if (currentTab === CertificationHubTab.PAST_CERTIFICATIONS) {
          ViewComponent = (
            <PastCertificationsView
              expiredCertifications={expiredCertifications}
              viewLimitedTo={viewLimitedTo}
            />
          )
        } else if (currentTab === CertificationHubTab.MARKETING) {
          ViewComponent = <MarketingToolkitView />
        }

        return (
          <Page>
            <BreadcrumbsItem to={URLS.CERTIFICATION_HUB.HUB}>Certification Hub</BreadcrumbsItem>
            {// If there's only one tab to show, hide the tab bar.
            tabs.length > 1 && (
              <AppBar position="static" color="default">
                <Tabs
                  value={currentTab}
                  variant="fullWidth"
                  scrollButtons="auto"
                  onChange={(e, value) => {
                    gaEvent({
                      action: `pageView${value}Tab`.replace(/'\s'/, ''),
                      category: 'CertificationHub',
                    })
                    setCurrentTab(value)
                  }}
                >
                  {tabs.map(tab => (
                    <Tab id={tab} key={tab} label={tab} value={tab} />
                  ))}
                </Tabs>
              </AppBar>
            )}
            {!hasSeenAppMessage &&
              (certification.status === CertificationStatusEnum.CERTIFIED ||
                certification.status === CertificationStatusEnum.NOT_CERTIFIED) && (
                <CertificationModalBanner certification={certification} currentUser={currentUser} />
              )}
            <Paper>{ViewComponent}</Paper>
          </Page>
        )
      }}
    </ResponseHandler>
  )
}

export default CertificationHubContainer

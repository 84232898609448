import React, { ReactElement } from 'react'

import { Grid, Typography, makeStyles, Dialog, IconButton } from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import ForwardIcon from '@material-ui/icons/ArrowForwardIos'
import CloseIcon from '@material-ui/icons/Close'
import cn from 'classnames'
import { format } from 'date-fns'
import isNil from 'lodash/isNil'

import { ReactComponent as EmailIcon } from 'assets/img/email-icon.svg'
import { ReactComponent as PhoneIcon } from 'assets/img/phone-icon.svg'
import { ReactComponent as SMSIcon } from 'assets/img/sms-text-icon.svg'
import PrintButton from 'components/Blocks/CustomButtons/PrintButton'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import Loader from 'components/Blocks/Loader'
import TitleWithInfoTooltip from 'components/Blocks/TitleWithInfoTooltip'
import {
  getParticipantType,
  getNpsData,
  NpsTag,
  CoreQScoreTag,
} from 'components/Insights/IndividualResults/IndividualResultsTab'
import useInsightsStyles, { border } from 'components/Insights/InsightsStyle'
import { NPS_QUESTION_CODES } from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import {
  BenchmarkCodeType,
  IndividualResultType,
  SurveyTypeEnum,
  QCategory,
  ParticipantSourceEnum,
  ParticipantTypeEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { getNpsAbbreviation, getNpsLabel } from 'utils'

const useStyles = makeStyles(({ spacing, palette }) => ({
  closeButtonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderBottom: border,
  },
  leftColumn: {
    width: '80%',
    '@media print': {
      width: '75%',
    },
  },
  rightColumn: {
    width: '20%',
    '@media print': {
      width: '25%',
    },
  },
  avoidPageBreak: {
    position: 'relative',
    pageBreak: 'avoid',
    display: 'block',
    pageBreakInside: 'avoid',
  },
  controlsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: border,
    '& >div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  textRow: {
    paddingTop: spacing(),
    '& >span': {
      color: palette.common.navy65,
    },
  },
  personalInfoDetails: {
    display: 'flex',
    background: palette.common.navy05,
    padding: spacing(3),
    marginTop: spacing(),
    '& >div': {
      marginRight: '10%',
      '& >p>span': {
        color: palette.common.navy65,
      },
    },
  },
  paginationButtons: {
    marginLeft: spacing(3),
    '& >div': {
      marginLeft: spacing(5),
      '& >button': {
        marginRight: -spacing(2),
      },
    },
  },
  npsFollowUp: {
    padding: spacing(2),
    '& >p:nth-child(2)': {
      paddingTop: spacing(),
    },
  },
  npsScoreBox: {
    marginTop: spacing(),
    background: palette.common.navy05,
    padding: spacing(2),
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.6rem',
      '&:nth-child(2)': {
        paddingLeft: spacing(3),
      },
    },
  },
  npsAnswer: {
    margin: spacing(),
  },
  responseColumn: {
    paddingLeft: spacing(2),
  },
  statementsBox: {
    '& >div': {
      '&:nth-of-type(even)': {
        backgroundColor: palette.common.navy05,
      },
      '& >div': {
        padding: spacing(2),
        '&:nth-child(1)': {
          borderRight: `1px solid ${palette.common.navy11}`,
        },
      },
    },
  },
  scoreTag: {
    borderRadius: 20,
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${palette.common.navy25}`,
  },
  answer: {
    marginTop: spacing(),
  },
  responsesBox: {
    padding: spacing(3),
    borderRadius: 3,
    border: `1px solid ${palette.common.navy25}`,
  },
  statementsTooltip: {
    padding: spacing(2),
  },
}))

type ResponsesByCode = {
  [key: string]: IndividualResultType['responses'][0] | null
}
type AnswerSectionProps = {
  responsesByCode: ResponsesByCode
  questions: IndividualResultType['questions']
}

const TESTIMONIAL_CODES = [
  BenchmarkCodeType.TESTIMONIALS_APPROVAL,
  BenchmarkCodeType.TESTIMONIALS_REVIEW,
  BenchmarkCodeType.TESTIMONIALS_PRIVACY,
]

const TestimonialSections: React.FC<AnswerSectionProps> = ({ responsesByCode, questions }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const testimonialQuestion = questions.find(
    q => q.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_APPROVAL,
  )
  if (!testimonialQuestion) {
    return null
  }
  const testimonialQuestionTexts = [testimonialQuestion.text]
  const responseTexts: Array<string | undefined | null> = []
  const testimonialApprovedText = testimonialQuestion.choices.find(
    choice => choice.benchmarkCode === 'yes',
  )?.text
  const testimonialApproved =
    responsesByCode[BenchmarkCodeType.TESTIMONIALS_APPROVAL]?.answer === testimonialApprovedText
  if (testimonialApproved) {
    testimonialQuestionTexts.push(
      questions.find(q => q.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_PRIVACY)?.text || '',
    )
    responseTexts.push(
      responsesByCode[BenchmarkCodeType.TESTIMONIALS_REVIEW]?.answer,
      responsesByCode[BenchmarkCodeType.TESTIMONIALS_PRIVACY]?.answer,
    )
  } else {
    responseTexts.push(
      testimonialQuestion.choices.find(choice => choice.benchmarkCode === 'no')?.text,
    )
  }
  return (
    <div className={cn(classes.fullRow, classes.avoidPageBreak)}>
      <Typography variant="h6">MARKETING TESTIMONIAL</Typography>
      <br />
      <div className={classes.responsesBox}>
        {testimonialQuestionTexts.map((text, idx) => {
          return (
            <>
              <Typography>{text}</Typography>
              <Typography color="textSecondary">{responseTexts[idx] || <i>Skipped</i>}</Typography>
              {idx < testimonialQuestionTexts.length - 1 && <br />}
            </>
          )
        })}
      </div>
    </div>
  )
}

const CustomSurveyResultDetails: React.FC<AnswerSectionProps> = ({
  responsesByCode,
  questions,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  return (
    <div className={classes.fullRow}>
      <Typography variant="h6">QUESTIONS</Typography>
      <br />
      {questions.map((question, idx) => {
        const firstChoice = question.choices[0]
        const lastChoice = question.choices[question.choices.length - 1]

        const response = responsesByCode[question.benchmarkCode || question.code]
        const { answer, answers } = response || {}
        return (
          <div key={question.code} id={`${idx}`} className={classes.avoidPageBreak}>
            <Typography>{question.text}</Typography>
            {question.isStatement && (
              <Typography color="textSecondary">
                <i>
                  (Linear Scale: {firstChoice.value} = {firstChoice.text},&nbsp;
                  {lastChoice.value} = {lastChoice.text})
                </i>
              </Typography>
            )}
            {!answer && !answers && (
              <Typography color="textSecondary" className={classes.answer}>
                <i>Skipped</i>
              </Typography>
            )}
            {!isNil(answer) && (
              <Typography
                className={cn({
                  [classes.scoreTag]: question.isStatement,
                  [classes.answer]: true,
                })}
                color="textSecondary"
              >
                {answer}
              </Typography>
            )}
            {answers &&
              answers.map((multiselectAnswer, index) => {
                return (
                  <Typography
                    key={multiselectAnswer}
                    color="textSecondary"
                    className={cn({ [classes.answer]: index === 0 })}
                  >
                    {multiselectAnswer}
                  </Typography>
                )
              })}
            <br />
            <br />
          </div>
        )
      })}
    </div>
  )
}

const ResultDetails: React.FC<AnswerSectionProps> = ({ responsesByCode, questions }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }

  const statements = questions.filter(q => q.isStatement)
  const comments = questions.filter(q => q.isOpenEnded)
  const demographics = questions.filter(q => q.category === QCategory.DEMOGRAPHICS)

  return (
    <>
      {statements.length && (
        <div className={cn(classes.fullRow, classes.avoidPageBreak)}>
          <Grid container>
            <div className={classes.leftColumn}>
              <TitleWithInfoTooltip
                title="Statements"
                tooltip={
                  <div className={classes.statementsTooltip}>
                    <Typography>Statements</Typography>
                    <Typography color="textSecondary">
                      The statement questions use a 5-point scale: Excellent = 5, Very Good = 4,
                      Good = 3, Average = 2, Poor = 1
                    </Typography>
                  </div>
                }
              />
            </div>
            <div className={classes.rightColumn}>
              <Typography variant="body2" color="textSecondary" className={classes.responseColumn}>
                RESPONSE
              </Typography>
            </div>
          </Grid>
          <div className={classes.statementsBox}>
            {statements.map((stmt, idx) => {
              const { answer } = responsesByCode[stmt.benchmarkCode || stmt.code] || {}
              return (
                <Grid container key={stmt.code} id={`response-box-${idx}`}>
                  <div className={classes.leftColumn}>
                    <Typography>{stmt.text}</Typography>
                  </div>
                  <div className={classes.rightColumn}>
                    <CoreQScoreTag includeSkipped answer={isNil(answer) ? null : answer} />
                  </div>
                </Grid>
              )
            })}
          </div>
        </div>
      )}
      {comments.length > 0 && (
        <div className={cn(classes.fullRow, classes.avoidPageBreak)}>
          <Typography variant="h6">COMMENTS</Typography>
          <br />
          <div className={classes.responsesBox}>
            {comments.map((question, idx) => {
              const answer = responsesByCode[question.benchmarkCode || question.code]?.answer
              return (
                <>
                  <Typography>{question.text}</Typography>
                  <Typography color="textSecondary">{answer || <i>Skipped</i>}</Typography>
                  {idx < comments.length - 1 && <br />}
                </>
              )
            })}
          </div>
        </div>
      )}
      {demographics.length > 0 && (
        <div className={cn(classes.fullRow, classes.avoidPageBreak)}>
          <Typography variant="h6">DEMOGRAPHICS</Typography>
          <br />
          <div className={classes.responsesBox}>
            {demographics.map((question, idx) => {
              const response = responsesByCode[question.benchmarkCode || question.code]
              const { answer, answers } = response || {}
              return (
                <>
                  <Typography>{question.text}</Typography>
                  {!answer && !answers && (
                    <Typography color="textSecondary">
                      <i>Skipped</i>
                    </Typography>
                  )}
                  {answer && <Typography color="textSecondary">{answer}</Typography>}
                  {answers &&
                    answers.map(multiselectAnswer => {
                      return (
                        <Typography key={multiselectAnswer} color="textSecondary">
                          {multiselectAnswer}
                        </Typography>
                      )
                    })}
                  {idx < demographics.length - 1 && <br />}
                </>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

type Props = {
  onClose(): void
  surveyName: string
  surveyType: SurveyTypeEnum
  productType: SurveyProductTypeEnum
  resultIndex: number
  numResults: number
  onPageChange(direction: 'next' | 'back'): void
  resultDetails: IndividualResultType
}
const IndividualResultDetails: React.FC<Props> = ({
  resultDetails,
  onClose,
  surveyName,
  resultIndex,
  numResults,
  onPageChange,
  surveyType,
  productType,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const participantType = getParticipantType(resultDetails)
  const responsesByCode: ResponsesByCode = resultDetails.responses.reduce(
    (acc, response) => ({
      ...acc,
      [response.questionCode]: response,
    }),
    {},
  )
  const questions = resultDetails.questions.filter(
    question =>
      ![...NPS_QUESTION_CODES, ...TESTIMONIAL_CODES].includes(
        question.benchmarkCode as BenchmarkCodeType,
      ),
  )
  const testimonialQuestions = questions.filter(question =>
    TESTIMONIAL_CODES.includes(question.benchmarkCode as BenchmarkCodeType),
  )
  // Read question text from participant's survey so we have a definitive source of which
  // questions the participant received and which version of text they were.
  const npsRecommendQuestion = resultDetails.questions.find(
    q => q.benchmarkCode === BenchmarkCodeType.NPS_RECOMMEND,
  )
  const npsFollowUpQuestion = resultDetails.questions.find(
    q => q.benchmarkCode === BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP,
  )
  const { npsGroup, npsAnswer } = getNpsData(resultDetails)
  const npsFollowUpResponse = resultDetails.responses.find(
    r => r.questionCode === BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP,
  )
  const dateTimeFormat = 'LLL dd, yyyy h:mm a'

  const respondedVia: { [key in ParticipantSourceEnum]: ReactElement } = {
    [ParticipantSourceEnum.CODE]: <>Survey Code </>,
    [ParticipantSourceEnum.PHONE]: (
      <>
        Phone call&nbsp;
        <PhoneIcon />
      </>
    ),
    [ParticipantSourceEnum.SMS]: (
      <>
        SMS&nbsp;
        <SMSIcon />
      </>
    ),
    [ParticipantSourceEnum.EMAIL]: (
      <>
        Email&nbsp;
        <EmailIcon />
      </>
    ),
    [ParticipantSourceEnum.PORTAL]: <></>,
  }

  return (
    <div>
      <div className={classes.closeButtonRow}>
        <IconButton onClick={onClose} id="close-detail">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.controlsRow}>
        <div className={classes.paginationButtons}>
          <Typography>
            INDIVIDUAL RESULTS: {resultIndex + 1} of {numResults}
          </Typography>
          <div>
            <IconButton onClick={() => onPageChange('back')} disabled={resultIndex === 0}>
              <BackIcon />
            </IconButton>
            <IconButton
              onClick={() => onPageChange('next')}
              disabled={resultIndex >= numResults - 1}
              id="next-page"
            >
              <ForwardIcon />
            </IconButton>
          </div>
        </div>
        <div>
          <PrintButton />
          <ScreenshotButton snapId="individual-results-details-snap" strategy="html2canvas" />
        </div>
      </div>
      <div id="individual-results-details-snap">
        <div className={classes.fullRow}>
          <Typography variant="h6">{surveyName.toUpperCase()}</Typography>
          {resultDetails.source !== ParticipantSourceEnum.PORTAL && resultDetails.source && (
            <Typography className={classes.textRow}>
              Responded via:{' '}
              <span className={classes.smallInfoIcon}>{respondedVia[resultDetails.source]}</span>
            </Typography>
          )}
          {resultDetails.source === ParticipantSourceEnum.PHONE && resultDetails.callTime && (
            <>
              <Typography className={classes.textRow}>
                Call time: <span>{format(new Date(resultDetails.callTime), dateTimeFormat)}</span>
              </Typography>
              <Typography className={classes.textRow}>
                Call length:{' '}
                <span>
                  {resultDetails.callLength
                    ? `${Math.floor(resultDetails.callLength / 60)} min ${resultDetails.callLength %
                        60} sec`
                    : 0}
                </span>
              </Typography>
            </>
          )}
          {resultDetails.source === ParticipantSourceEnum.SMS &&
            resultDetails.lastSentNotificationTimestamp && (
              <Typography className={classes.textRow}>
                SMS sent:{' '}
                <span>
                  {format(new Date(resultDetails.lastSentNotificationTimestamp), dateTimeFormat)}
                </span>
              </Typography>
            )}
          {resultDetails.source === ParticipantSourceEnum.EMAIL &&
            resultDetails.lastSentNotificationTimestamp && (
              <Typography className={classes.textRow}>
                Email sent:{' '}
                <span>
                  {format(new Date(resultDetails.lastSentNotificationTimestamp), dateTimeFormat)}
                </span>
              </Typography>
            )}
          <Typography className={classes.textRow}>
            Survey Started:{' '}
            <span>{format(new Date(resultDetails.startedTimestamp), dateTimeFormat)}</span>
          </Typography>
          {resultDetails.dischargeDate && (
            <Typography className={classes.textRow}>
              Discharge Date:{' '}
              <span>
                {resultDetails.dischargeDate &&
                  format(new Date(resultDetails.dischargeDate), 'LLL dd, yyyy')}
              </span>
            </Typography>
          )}
        </div>
        <div className={classes.fullRow}>
          <Typography variant="h6">PERSONAL INFO</Typography>
          <div className={classes.personalInfoDetails}>
            <div>
              <Typography>{resultDetails.name}</Typography>
              <Typography color="textSecondary">{resultDetails.locationName}</Typography>
            </div>
            <div>
              {participantType && (
                <Typography>
                  Participant Type: <span>{participantType}</span>
                </Typography>
              )}
              {resultDetails.participantType === ParticipantTypeEnum.REPRESENTATIVE && (
                <Typography>
                  Client Name: <span>{resultDetails.clientName}</span>
                </Typography>
              )}
              {resultDetails.jobTitle && (
                <Typography>
                  Job Title: <span>{resultDetails.jobTitle}</span>
                </Typography>
              )}
              <Typography>
                Level of Care: <span>{resultDetails.levelOfCare}</span>
              </Typography>
            </div>
          </div>
        </div>
        {npsRecommendQuestion && npsFollowUpQuestion && (
          <div className={classes.fullRow}>
            <Grid container>
              <div className={classes.leftColumn}>
                <TitleWithInfoTooltip
                  title={getNpsLabel(productType, false).toUpperCase()}
                  padIcon={false}
                  tooltip={
                    <div>
                      <Typography>{getNpsLabel(productType, false)}</Typography>
                      <Typography color="textSecondary">
                        Respondents are given a linear scale rating (0 to 10) to answer the{' '}
                        {getNpsAbbreviation(productType)}
                        question. Detractors (0-6), Passives (7-8), and Promoters (9-10).
                      </Typography>
                    </div>
                  }
                />
              </div>
              <div className={classes.rightColumn}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.responseColumn}
                >
                  RESPONSE
                </Typography>
              </div>
            </Grid>
            <Grid container className={classes.npsScoreBox}>
              <div className={classes.leftColumn}>
                <Typography>{npsRecommendQuestion.text}</Typography>
              </div>
              <div className={classes.rightColumn}>
                {npsGroup && npsAnswer != null ? (
                  <>
                    <NpsTag npsGroup={npsGroup} npsAnswer={npsAnswer} width={140} />
                  </>
                ) : (
                  <Typography color="textSecondary">
                    <i>Skipped</i>
                  </Typography>
                )}
              </div>
            </Grid>
            <div className={classes.npsFollowUp} id="individual-nps-response">
              <Typography>{npsFollowUpQuestion.text}</Typography>
              <Typography color="textSecondary">
                {npsFollowUpResponse?.answer || <i>Skipped</i>}
              </Typography>
            </div>
          </div>
        )}
        {surveyType === SurveyTypeEnum.RESIDENT_CUSTOM ? (
          <CustomSurveyResultDetails questions={questions} responsesByCode={responsesByCode} />
        ) : (
          <ResultDetails questions={questions} responsesByCode={responsesByCode} />
        )}
        <TestimonialSections questions={testimonialQuestions} responsesByCode={responsesByCode} />
      </div>
    </div>
  )
}

const IndividualResultDetailsContainer: React.FC<Props & {
  tableIsLoading: boolean
}> = props => {
  return (
    <Dialog fullWidth maxWidth="lg" open>
      {// When resultDetails is null, we are loading more results from the main table.
      props.tableIsLoading ? <Loader /> : <IndividualResultDetails {...props} />}
    </Dialog>
  )
}

export default IndividualResultDetailsContainer
